import React, { useEffect, useState } from "react";
import CardContainer from "components/card/CardContainer";
import Widget from "components/widget/Widget";
import { MdDataArray, MdShortText, MdTextFields } from "react-icons/md";
import BasicModal from "components/modal/BasicModal";
import { useDisclosure } from "@chakra-ui/hooks";
import InputField from "components/fields/InputField";
import { useForm } from "react-hook-form";
import TextField from "components/fields/TextField";
import { toast } from "react-toastify";
import { getHomeContent } from "api/PageAPI";
import { updateHomeSection } from "api/HomeAPI";

const HomeAreaWidgets = () => {
  const section = "coverage-area";
  const pageId = 1;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [content, setContent] = useState({
    loading: true,
    data: null,
  });
  const [sectionId, setSectionId] = useState(null);
  const [order, setOrder] = useState(null);
  const [updateName, setUpdateName] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const showUpdateModal = (name) => {
    setUpdateName(name);
    reset({
      [name + "_en"]: content?.data[name + "_en"],
      [name + "_id"]: content?.data[name + "_id"],
    });

    onOpen();
  };

  const onSubmit = async (data) => {
    let promise = null;
    let finalBody = {
      page_id: pageId,
      order: order,
      name: content.name,
      body: {
        ...content.data,
        ...data,
      },
    };

    finalBody.body = JSON.stringify(finalBody.body);
    promise = updateHomeSection(sectionId, finalBody);

    toast.promise(promise, {
      pending: "Loading...",
      success: "Berhasil di-update!",
      error: "Gagal di-update!",
    });

    promise
      .then((res) => {
        if (res.status === 200) {
          getContent();
          onClose();
          reset();
        }
      })
      .catch((err) => {
        console.error(err);
      });

    onClose();
  };

  const getContent = () => {
    getHomeContent().then((res) => {
      const index = res.data.data.findIndex(
        (item) => item.name === section.replace(/-/g, "_")
      );
      setSectionId(res.data.data[index].id);
      setOrder(res.data.data[index].order);
      setContent({
        loading: false,
        name: res.data.data[index].name,
        data: res.data.data[index].body,
      });
    });
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
    <>
      <CardContainer
        title="Area Jangkauan"
        description="Atur element yang ada pada section area jangkauan di sini"
      >
        <div className="flex flex-col gap-3">
          <Widget
            isLoading={content?.loading}
            title="Subtitle"
            subtitle={content.data && content.data.subtitle_id}
            icon={<MdShortText />}
            updateUrl={() => showUpdateModal("subtitle")}
          />
          <Widget
            isLoading={content?.loading}
            title="Title"
            subtitle={content.data && content.data.title_id}
            icon={<MdTextFields />}
            updateUrl={() => showUpdateModal("title")}
          />
          <Widget
            isLoading={content?.loading}
            title="Data"
            subtitle={
              content.data &&
              content.data?.coverage
                .map((item) => item?.region)
                .join(", ")
                .toUpperCase()
            }
            icon={<MdDataArray />}
            viewUrl="regions"
          />
        </div>
      </CardContainer>
      <BasicModal isOpen={isOpen} onClose={onClose} header="Update Data">
        <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
          {updateName === "text" || updateName === "description" ? (
            <>
              <TextField
                extra="mb-3"
                label={`${
                  updateName.charAt(0).toUpperCase() + updateName.slice(1)
                } (EN)*`}
                id={`${updateName}_en`}
                type="text"
                name={`${updateName}_en`}
                errors={errors}
                register={register}
                rows={10}
                defaultValue={""}
                validationSchema={{
                  required: `${
                    updateName.charAt(0).toUpperCase() + updateName.slice(1)
                  } (EN) harus diisi`,
                }}
              />
              <TextField
                extra="mb-3"
                label={`${
                  updateName.charAt(0).toUpperCase() + updateName.slice(1)
                } (ID)*`}
                id={`${updateName}_id`}
                type="text"
                name={`${updateName}_id`}
                errors={errors}
                register={register}
                rows={10}
                defaultValue={""}
                validationSchema={{
                  required: `${
                    updateName.charAt(0).toUpperCase() + updateName.slice(1)
                  } (ID) harus diisi`,
                }}
              />
            </>
          ) : (
            <>
              <InputField
                extra="mb-3"
                label={`${
                  updateName.charAt(0).toUpperCase() + updateName.slice(1)
                } (EN)*`}
                id={`${updateName}_en`}
                type="text"
                name={`${updateName}_en`}
                errors={errors}
                register={register}
                defaultValue={""}
                validationSchema={{
                  required: `${
                    updateName.charAt(0).toUpperCase() + updateName.slice(1)
                  } (EN) harus diisi`,
                }}
              />
              <InputField
                extra="mb-3"
                label={`${
                  updateName.charAt(0).toUpperCase() + updateName.slice(1)
                } (ID)*`}
                id={`${updateName}_id`}
                type="text"
                name={`${updateName}_id`}
                errors={errors}
                register={register}
                defaultValue={""}
                validationSchema={{
                  required: `${
                    updateName.charAt(0).toUpperCase() + updateName.slice(1)
                  } (ID) harus diisi`,
                }}
              />
            </>
          )}
          {/* Button Submit & Close */}
          <div className="float-right mt-8 flex gap-3">
            <button
              className="linear rounded-[20px] bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
              type="submit"
            >
              Update
            </button>
            <button
              className="linear rounded-[20px] bg-white px-4 py-2 text-base font-semibold text-gray-600 transition duration-200 dark:bg-transparent dark:text-white dark:opacity-90 dark:active:opacity-90"
              onClick={() => {
                reset();
                onClose();
              }}
              type="button"
            >
              Batal
            </button>
          </div>
        </form>
      </BasicModal>
    </>
  );
};

export default HomeAreaWidgets;
