import { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";

export const DocumentPreview = (props) => {
  const { file } = props;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  return (
    <div className="m-3">
      <nav className="flex flex-col items-center gap-2">
        <div className="flex gap-2">
          <button
            className="rounded-lg bg-brand-800 px-3 py-1 text-center text-white"
            type="button"
            onClick={goToPrevPage}
          >
            Prev
          </button>
          <button
            className="rounded-lg bg-brand-800 px-3 py-1 text-center text-white"
            type="button"
            onClick={goToNextPage}
          >
            Next
          </button>
        </div>
        <p>
          Page {pageNumber} of {numPages}
        </p>
      </nav>
      <div className="react-pdf-wrapper">
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          <Page size="A4" pageNumber={pageNumber} renderTextLayer={false} />
        </Document>
      </div>
    </div>
  );
};
