import React from "react";
import CardContainer from "components/card/CardContainer";
import Widget from "components/widget/Widget";
import { showAllProduct } from "api/ProductAPI";
import { toast } from "react-toastify";
import { getHomeContent } from "api/PageAPI";
import { updateHomeSection } from "api/HomeAPI";

const HomeProductData = () => {
  const section = "products";
  const pageId = 1;
  const [items, setItems] = React.useState([]);
  const [sectionProp, setSectionProp] = React.useState({});

  const getAllProduct = () => {
    showAllProduct()
      .then((res) => {
        setItems(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        toast.error("Gagal memuat data produk. Silahkan coba lagi!");
      });
  };

  const handleHighlight = (id) => {
    const highlightedId = sectionProp.body || [];

    if (highlightedId?.length < 3) highlightedId.push(id);
    if (highlightedId?.length === 3) {
      highlightedId.pop();
      highlightedId.unshift(id);
    }

    const finalBody = {
      section_id: sectionProp.id,
      name: sectionProp.name,
      page_id: pageId,
      order: sectionProp.order,
      body: highlightedId,
    };

    finalBody.body = JSON.stringify(finalBody.body);
    const promise = updateHomeSection(sectionProp.id, finalBody);

    toast.promise(promise, {
      pending: "Loading...",
      success: "Berhasil dihighlight!",
      error: "Gagal dihighlihgt!",
    });

    promise
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          getContent();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getContent = () => {
    getHomeContent().then((res) => {
      const index = res.data.data.findIndex(
        (item) => item.name === section.replace(/-/g, "_")
      );
      setSectionProp(res.data.data[index]);
    });
  };

  React.useEffect(() => {
    getAllProduct();
    getContent();
  }, []);

  return (
    <CardContainer
      title="Produk di Home Page"
      description="Berikut adalah daftar produk yang akan ditampilkan di Home Page."
    >
      <div className="flex flex-col gap-3">
        {items.map((item, index) => (
          <div key={index}>
            <Widget
              title={`Produk ${index + 1}`}
              subtitle={item.name}
              img={process.env.REACT_APP_API_ENDPOINT + "/images/" + item.img}
              highlightData={() => {
                handleHighlight(item.id);
              }}
            />
          </div>
        ))}
      </div>
    </CardContainer>
  );
};

export default HomeProductData;
