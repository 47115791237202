import {
  MdBrandingWatermark,
  MdCardMembership,
  MdGasMeter,
  MdMap,
  MdMediation,
  MdNewspaper,
  MdPropane,
  MdViewCarousel,
} from "react-icons/md";

export const homeElTypes = [
  {
    id: "draggable-1",
    type: "carousel",
    name: "Carousel",
    icon: <MdViewCarousel />,
    baseUrl: "carousel",
    hasFormPage: false,
  },
  {
    id: "draggable-2",
    type: "logo-carousel",
    name: "Carousel Logo",
    icon: <MdBrandingWatermark />,
    baseUrl: "logo-carousel",
    hasFormPage: false,
  },
  {
    id: "draggable-3",
    type: "short-about",
    name: "Tentang Kami",
    icon: <MdCardMembership />,
    baseUrl: "short-about",
    hasFormPage: false,
  },
  {
    id: "draggable-4",
    type: "coverage-area",
    name: "Area Jangkauan",
    file: undefined,
    icon: <MdMap />,
    baseUrl: "coverage-area",
    hasFormPage: false,
  },
  {
    id: "draggable-5",
    type: "short-facilities",
    name: "Fasilitas Singkat",
    icon: <MdGasMeter />,
    baseUrl: "short-facilities",
    hasFormPage: false,
  },
  {
    id: "draggable-6",
    type: "products-carousel",
    name: "Carousel Produk",
    icon: <MdPropane />,
    baseUrl: "products",
  },
  {
    id: "draggable-7",
    type: "company-news",
    name: "Kegiatan Perusahaan",
    icon: <MdNewspaper />,
    baseUrl: "company-news",
  },
  {
    id: "draggable-8",
    type: "social-media",
    name: "Media Sosial",
    icon: <MdMediation />,
    baseUrl: "social-media",
  },
];
