import axiosInstance from "./config";

export const createTable = (body) => {
  return axiosInstance.post("/tables", body);
};

export const updateTable = (id, body) => {
  return axiosInstance.post(`/tables/update/${id}`, body);
};

export const showTable = (id) => {
  return axiosInstance.get(`/tables/${id}`);
};

export const deleteTable = (id) => {
  return axiosInstance.get(`/tables/delete/${id}`);
};
