import {
  MdCardMembership,
  MdDescription,
  MdHorizontalRule,
  MdListAlt,
  MdMap,
  MdPieChart,
  MdShortText,
  MdTextFields,
} from "react-icons/md";
import { BiCard, BiDoughnutChart, BiImage, BiTable } from "react-icons/bi";
import {
  BsCardList,
  BsImageAlt,
  BsLayoutSidebarInsetReverse,
  BsLink,
} from "react-icons/bs";
import { CgTemplate } from "react-icons/cg";
import { HiTemplate } from "react-icons/hi";
import {
  TbLayout,
  TbLayoutBoard,
  TbLayoutBoardSplit,
  TbLayoutGrid,
  TbLayoutNavbar,
  TbSection,
  TbSquareLetterT,
} from "react-icons/tb";
import { TfiLayoutMediaRight } from "react-icons/tfi";
import { RiLayoutTop2Line } from "react-icons/ri";

export const productElements = [
  {
    id: "el-2",
    type: "divider",
    name: "Pembatas",
    product_id: 0,
    order: 0,
    body: {},
    icon: <MdHorizontalRule />,
  },
  {
    id: "el-3",
    type: "title",
    name: "Judul",
    product_id: 0,
    order: 0,
    body: {},
    icon: <MdTextFields />,
  },
  {
    id: "el-4",
    type: "subtitle",
    name: "Sub Judul",
    product_id: 0,
    order: 0,
    body: {},
    icon: <MdShortText />,
  },
  {
    id: "el-5",
    type: "text",
    name: "Deskripsi",
    product_id: 0,
    order: 0,
    body: {},
    icon: <MdDescription />,
  },
  {
    id: "el-6",
    type: "page-image",
    name: "Gambar",
    product_id: 0,
    order: 0,
    body: {},
    icon: <BiImage />,
  },
  {
    id: "el-7",
    type: "product-card",
    name: "Product Card",
    product_id: 0,
    order: 0,
    body: {},
    icon: <BiCard />,
  },
  {
    id: "el-8",
    type: "table",
    name: "Table",
    product_id: 0,
    order: 0,
    body: {},
    icon: <BiTable />,
  },
  {
    id: "el-9",
    type: "card-list",
    name: "Card List",
    product_id: 0,
    order: 0,
    body: {},
    icon: <BsCardList />,
  },
  {
    id: "el-10",
    type: "chart",
    name: "Chart",
    product_id: 0,
    order: 0,
    body: {},
    icon: <MdPieChart />,
  },
  {
    id: "el-10",
    type: "text-box",
    name: "Text Box",
    product_id: 0,
    order: 0,
    body: {},
    icon: <TbSquareLetterT />,
  },
];

export const HIWElements = [
  {
    id: "el-1",
    type: "jumbotron",
    name: "Jumbotron",
    product_id: 0,
    order: 0,
    body: {},
    icon: <TfiLayoutMediaRight />,
  },
  {
    id: "el-2",
    type: "divider",
    name: "Pembatas",
    product_id: 0,
    order: 0,
    body: {},
    icon: <MdHorizontalRule />,
  },
  {
    id: "el-3",
    type: "title",
    name: "Judul",
    product_id: 0,
    order: 0,
    body: {},
    icon: <MdTextFields />,
  },
  {
    id: "el-4",
    type: "subtitle",
    name: "Sub Judul",
    product_id: 0,
    order: 0,
    body: {},
    icon: <MdShortText />,
  },
  {
    id: "el-5",
    type: "text",
    name: "Deskripsi",
    product_id: 0,
    order: 0,
    body: {},
    icon: <MdDescription />,
  },
  {
    id: "el-6",
    type: "page-image",
    name: "Gambar",
    product_id: 0,
    order: 0,
    body: {},
    icon: <BiImage />,
  },
  {
    id: "el-7",
    type: "product-card",
    name: "Product Card",
    product_id: 0,
    order: 0,
    body: {},
    icon: <BiCard />,
  },
  {
    id: "el-8",
    type: "table",
    name: "Table",
    product_id: 0,
    order: 0,
    body: {},
    icon: <BiTable />,
  },
  {
    id: "el-9",
    type: "card-list",
    name: "Card List",
    product_id: 0,
    order: 0,
    body: {},
    icon: <BsCardList />,
  },
  {
    id: "el-10",
    type: "chart",
    name: "Chart",
    product_id: 0,
    order: 0,
    body: {},
    icon: <MdPieChart />,
  },
  {
    id: "el-10",
    type: "text-box",
    name: "Text Box",
    product_id: 0,
    order: 0,
    body: {},
    icon: <TbSquareLetterT />,
  },
];

export const generalElements = [
  {
    id: "el-1",
    type: "divider",
    name: "Pembatas",
    page_id: 0,
    section_id: 0,
    order: 0,
    body: {},
    icon: <MdHorizontalRule />,
  },
  {
    id: "el-2",
    type: "title",
    name: "Judul",
    page_id: 0,
    section_id: 0,
    order: 0,
    body: {},
    icon: <MdTextFields />,
  },
  {
    id: "el-3",
    type: "subtitle",
    name: "Sub Judul",
    page_id: 0,
    section_id: 0,
    order: 0,
    body: {},
    icon: <MdShortText />,
  },
  {
    id: "el-4",
    type: "link",
    name: "Text Link",
    page_id: 0,
    section_id: 0,
    order: 0,
    body: {},
    icon: <BsLink />,
  },
  {
    id: "el-5",
    type: "text",
    name: "Deskripsi",
    page_id: 0,
    section_id: 0,
    order: 0,
    body: {},
    icon: <MdDescription />,
  },
  {
    id: "el-6",
    type: "page-image",
    name: "Gambar",
    product_id: 0,
    order: 0,
    body: {},
    icon: <BiImage />,
  },
  {
    id: "el-7",
    type: "member-card",
    name: "Member Card",
    page_id: 0,
    section_id: 0,
    order: 0,
    body: {},
    icon: <MdCardMembership />,
  },
  {
    id: "el-8",
    type: "image-list",
    name: "Image List",
    page_id: 0,
    section_id: 0,
    order: 0,
    body: {},
    icon: <BsImageAlt />,
  },
  {
    id: "el-9",
    type: "list",
    name: "List",
    page_id: 0,
    section_id: 0,
    order: 0,
    body: {},
    icon: <MdListAlt />,
  },
  {
    id: "el-10",
    type: "chart",
    name: "Chart",
    page_id: 0,
    section_id: 0,
    order: 0,
    body: {},
    icon: <MdPieChart />,
  },
  {
    id: "el-11",
    type: "map-chart",
    name: "Map Chart",
    page_id: 0,
    section_id: 0,
    order: 0,
    body: {},
    icon: <MdMap />,
  },
  {
    id: "el-12",
    type: "template-1",
    name: "Template 1",
    page_id: 0,
    section_id: 0,
    order: 0,
    body: {},
    icon: <TbLayout />,
  },
  {
    id: "el-13",
    type: "template-2",
    name: "Template 2",
    page_id: 0,
    section_id: 0,
    order: 0,
    body: {},
    icon: <TbLayoutBoardSplit />,
  },
  {
    id: "el-14",
    type: "template-3",
    name: "Template 3",
    page_id: 0,
    section_id: 0,
    order: 0,
    body: {},
    icon: <RiLayoutTop2Line />,
  },
  {
    id: "el-15",
    type: "template-4",
    name: "Template 4",
    page_id: 0,
    section_id: 0,
    order: 0,
    body: {},
    icon: <TbLayoutGrid />,
  },
  {
    id: "el-16",
    type: "template-5",
    name: "Template 5",
    page_id: 0,
    section_id: 0,
    order: 0,
    body: {},
    icon: <TbLayoutNavbar />,
  },
  {
    id: "el-17",
    type: "template-6",
    name: "Template 6",
    page_id: 0,
    section_id: 0,
    order: 0,
    body: {},
    icon: <BiDoughnutChart />,
  },
];
