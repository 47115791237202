import axiosInstance from "./config";

export const showAllMilestone = () => {
  return axiosInstance.get("/milestone");
};

export const showMilestone = (id) => {
  return axiosInstance.get(`/milestone/${id}`);
};

export const createMilestone = (body) => {
  return axiosInstance.post("/milestone", body);
};

export const deleteMilestone = (id) => {
  return axiosInstance.get(`/milestone/delete/${id}`);
};

export const updateMilestone = (id, body) => {
  return axiosInstance.post(`/milestone/update/${id}`, body);
};
