import axiosInstance from "./config";

export const showAllShareholder = () => {
  return axiosInstance.get("/shareholders");
};

export const showShareholder = (id) => {
  return axiosInstance.get(`/shareholders/${id}`);
};

export const createShareholder = (body) => {
  return axiosInstance.post("/shareholders", body);
};

export const deleteShareholder = (id) => {
  return axiosInstance.get(`/shareholders/delete/${id}`);
};

export const updateShareholder = (id, body) => {
  return axiosInstance.post(`/shareholders/update/${id}`, body);
};
