import React from "react";

// Libs
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDisclosure } from "@chakra-ui/hooks";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { KeyboardSensor, useSensor, useSensors } from "@dnd-kit/core";

// APIs
import { elementActionHandler } from "utils/API";
import { getAllPageContent } from "api/PageAPI";
import { getPageContent } from "api/PageAPI";
import { uploadImages } from "api/ImageAPI";
import { getYoutubeID } from "utils";
import { getVideoPreviewData } from "utils/API";

// Components
import { generalElements } from "variables/elements";
import LockedLayout from "views/admin/page-builder/locked-layout";
import ElementsList from "views/admin/page-builder/components/ElementsList";
import MyPointerSensor from "views/admin/page-builder/components/PointerSensor";
import DropzoneFile from "components/dropzone/DropzoneFile";
import BasicModal from "components/modal/BasicModal";
import Text from "./components/modals/Text";
import Title from "./components/modals/Title";
import Link from "./components/modals/Link";
import List from "./components/modals/List";
import MemberCards from "./components/modals/MemberCard";
import SingleImage from "./components/modals/SingleImage";
import Template3 from "./components/modals/Template3";
import Template2 from "./components/modals/Template2";
import Template1 from "./components/modals/Template1";
import Template5 from "./components/modals/Template5";
import Template6 from "./components/modals/Template6";
import Template4 from "./components/modals/Template4";

const LayoutCanvas = () => {
  const navigate = useNavigate();
  const elementTypes = generalElements;
  const location = useLocation();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = React.useState(false);
  const [listType, setListType] = React.useState(null);
  const [pageData, setPageData] = React.useState({});
  const [updateName, setUpdateName] = React.useState("");
  const [file, setFile] = React.useState(undefined);
  const [progress, setProgress] = React.useState(0);
  const [isUploading, setIsUploading] = React.useState(false);
  const [elements, setElements] = React.useState([]);
  const [oldElements, setOldElements] = React.useState([]);
  const [deletedElements, setDeletedElements] = React.useState([]);
  const [oldData, setOldData] = React.useState({});

  // Template's States
  const [existingImages, setExistingImages] = React.useState([]);
  const [tmplt1Files, setTmplt1Files] = React.useState([]);
  const [tmplt2Files, setTmplt2Files] = React.useState([]);
  const [tmplt3Files, setTmplt3Files] = React.useState([]);
  const [tmplt4Files, setTmplt4Files] = React.useState([]);
  const [preview, setPreview] = React.useState([]);
  const [thumbnails, setThumbnails] = React.useState([]);
  const [videos, setVideos] = React.useState([]);

  const sensors = useSensors(
    useSensor(MyPointerSensor, {
      onActivation: (event) => {
        return;
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const dropFile = (file) => {
    if (updateName === "template-1") {
      if (tmplt1Files.length < 3) {
        setTmplt1Files([...tmplt1Files, file]);
      }
    } else if (updateName === "template-2") {
      setTmplt2Files([file]);
    } else if (updateName === "template-3") {
      if (tmplt3Files.length < 3) {
        setTmplt3Files([...tmplt3Files, file]);
      }
    } else if (updateName === "template-4") {
      setTmplt4Files([file]);
    } else setFile(file);
  };

  const removeFile = () => {
    if (updateName === "template-1") {
      setTmplt1Files([]);
    } else if (updateName === "template-2") {
      setTmplt2Files([]);
    } else if (updateName === "template-3") {
      setTmplt3Files([]);
    } else if (updateName === "template-4") {
      setTmplt4Files([]);
    } else setFile(undefined);
  };

  const handleExtendedChart = () => {
    if (oldData?.body) {
      if (!oldData?.body?.chart_id) {
        const chartBody = {
          page_id: null,
          section_id: null,
          order: 1,
          body: {},
        };
        chartBody.body = JSON.stringify(chartBody.body);
        let promise = elementActionHandler(chartBody, "chart", "create");

        toast.promise(promise, {
          pending: "Membuat Chart...",
          success: "Chart berhasil dibuat!",
          error: "Gagal membuat Chart",
        });

        promise
          .then((res) => {
            updateBodyElement();

            const finalBody = {
              id: oldData.default_id,
              order: oldData.order,
              type: parseInt(updateName.split("-")[1]),
              body: {
                ...oldData.body,
                chart_id: res.data.data.data.id,
              },
            };

            finalBody.body = JSON.stringify(finalBody.body);
            promise = elementActionHandler(finalBody, updateName, "update");

            toast.promise(promise, {
              pending: "Menambahkan Chart ke Template...",
              success: "Chart berhasil ditambahkan ke Template!",
              error: "Gagal menambahkan Chart ke Template",
            });

            promise
              .then((res) => {
                navigate(`charts/${res.data.data.data.body.chart_id}`);
              })
              .catch((err) => {
                console.error(err);
              });
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        navigate(`charts/${oldData.body.chart_id}`);
      }
    }
  };

  const addElement = (el) => {
    const newEl = {
      ...el,
      id: "el-" + Math.floor(Math.random() * 100000),
      order: elements.length + 1,
      page_id: pageData.id,
    };
    setElements([...elements, newEl]);
  };

  const arrangeElement = (el) => {
    // Change order element as index order
    const newElements = el.map((element, index) => {
      return {
        ...element,
        order: index + 1,
      };
    });
    setElements(newElements);
  };

  const removeElement = (el) => {
    const deletedEl = elements.find((element) => element.id === el);
    if (oldElements.find((element) => element.id === el))
      setDeletedElements([...deletedElements, deletedEl]);

    const newElements = elements.filter((element) => element.id !== el);
    setElements(newElements);
  };

  const updateElementBody = (_action, id) => {
    const targetEl = elements.find((el) => el.id === id);
    const targetType = targetEl?.default_type ?? targetEl.type;

    targetType === "product-card" &&
      navigate(`${location.pathname}/product-cards/${targetEl.default_id}`);
    targetType === "table" &&
      navigate(`${location.pathname}/tables/${targetEl.default_id}`);
    targetType === "card-list" &&
      navigate(`${location.pathname}/card-lists/${targetEl.default_id}`);
    targetType === "image-list" &&
      navigate(`${location.pathname}/image-lists/${targetEl.default_id}`);
    targetType === "section" &&
      navigate(`${location.pathname}/section/${targetEl.default_id}`);
    targetType === "chart" &&
      navigate(`${location.pathname}/charts/${targetEl.default_id}`);
    targetType === "map-chart" &&
      navigate(`${location.pathname}/map-charts/${targetEl.default_id}`);
    targetType === "page-image" &&
      setExistingImages([elements.find((el) => el.id === id)?.body?.img]);
    targetType === "jumbotron" &&
      setExistingImages([elements.find((el) => el.id === id)?.body?.img]);
    targetType === "list" &&
      setListType(elements.find((el) => el.id === id)?.body?.type);
    targetType === "member-card" &&
      setExistingImages([elements.find((el) => el.id === id)?.body?.img]);
    targetType === "template-1" &&
      setExistingImages(elements.find((el) => el.id === id)?.body?.img);
    targetType === "template-3" &&
      setExistingImages(elements.find((el) => el.id === id)?.body?.img);
    targetType === "template-4" &&
      setExistingImages(elements.find((el) => el.id === id)?.body?.img);
    if (targetType === "template-2") {
      setExistingImages(elements.find((el) => el.id === id)?.body?.img);
      elements.find((el) => el.id === id)?.body?.youtube &&
        handleInputVideo(elements.find((el) => el.id === id)?.body?.youtube);
    }

    setUpdateName(targetType);
    setOldData(targetEl);
    reset({
      title_en: targetEl.body.title_en,
      title_id: targetEl.body.title_id,
      text_en: targetEl.body.text_en,
      text_id: targetEl.body.text_id,
      youtube: targetEl.body.youtube,
      position_en: targetEl.body.position_en,
      subtitle_en: targetEl.body.subtitle_en,
      subtitle_id: targetEl.body.subtitle_id,
      position_id: targetEl.body.position_id,
      [targetType]: targetEl.body[targetType],
      [targetType + "_en"]: targetEl.body[targetType + "_en"],
      [targetType + "_id"]: targetEl.body[targetType + "_id"],
      en: Array.isArray(targetEl.body["en"])
        ? targetEl.body["en"].join("\n")
        : targetEl.body["en"],
      id: Array.isArray(targetEl.body["id"])
        ? targetEl.body["id"].join("\n")
        : targetEl.body["id"],
      list_en: Array.isArray(targetEl.body["list_en"])
        ? targetEl.body["list_en"].join("\n")
        : targetEl.body["list_en"],
      list_id: Array.isArray(targetEl.body["list_id"])
        ? targetEl.body["list_id"].join("\n")
        : targetEl.body["list_id"],
      name: targetEl.body.name,
    });
    onOpen();
  };

  const refactorElements = (el) => {
    const newEl = el.map((element) => {
      const elementType = elementTypes.find(
        (el) => el.type === element.name.replace(/_/g, "-").slice(0, -1)
      );

      let newElement = {
        default_id: element.id,
        id: "el-" + Math.floor(Math.random() * 100000),
        order: element.order,
        page_id: element.page_id,
        type: element.name.replace(/_/g, "-").slice(0, -1),
        icon: elementType?.icon,
        name: elementType?.name,
        body: { ...element.body },
      };

      if (element.name === "templates") {
        newElement = {
          ...newElement,
          icon: elementTypes.find(
            (el) => `${element.name.slice(0, -1)}-${element.type}` === el.type
          )?.icon,
          name: elementTypes.find(
            (el) => `${element.name.slice(0, -1)}-${element.type}` === el.type
          )?.name,
          default_type: `${element.name.slice(0, -1)}-${element.type}`,
        };
      }

      return newElement;
    });
    setElements(newEl);
    setOldElements(newEl);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const promises = [];
    let finalBody = null;
    const isIdentical =
      JSON.stringify(oldElements) === JSON.stringify(elements);

    finalBody = {
      page_id: pageData.id,
    };

    if (!isIdentical && oldElements.length === 0) {
      elements.forEach((el, index) => {
        el.type === "template-1" &&
          (finalBody = {
            ...finalBody,
            order: index + 1,
            type: 1,
            body: {
              with_bg: true,
              bg_image: "",
              bg_size: "cover",
              title_id: "",
              title_en: "",
              dividers: true,
              text_id: "",
              text_en: "",
              img: [],
            },
          });
        el.type === "template-2" &&
          (finalBody = {
            ...finalBody,
            order: index + 1,
            type: 2,
            body: {
              with_bg: true,
              bg_image: "",
              bg_size: "cover",
              subtitle_id: "",
              subtitle_en: "",
              title_id: "",
              title_en: "",
              dividers: true,
              img: "",
              youtube: "",
            },
          });
        el.type === "template-3" &&
          (finalBody = {
            ...finalBody,
            order: index + 1,
            type: 3,
            body: {
              with_bg: true,
              bg_image: "",
              bg_size: "cover",
              title_id: "",
              title_en: "",
              dividers: true,
              text_id: "",
              text_en: "",
              img: [],
            },
          });
        el.type === "template-4" &&
          (finalBody = {
            ...finalBody,
            order: index + 1,
            type: 4,
            body: {
              with_bg: true,
              bg_image: "",
              bg_size: "cover",
              subtitle_id: "",
              subtitle_en: "",
              title_id: "",
              title_en: "",
              img: [],
              list_en: [],
              list_id: [],
            },
          });
        el.type === "template-5" &&
          (finalBody = {
            ...finalBody,
            order: index + 1,
            type: 5,
            body: {
              with_bg: false,
              bg_image: "",
              bg_size: "cover",
              subtitle_id: "",
              subtitle_en: "",
              title_id: "",
              title_en: "",
              chart_id: 0,
            },
          });
        el.type === "template-6" &&
          (finalBody = {
            ...finalBody,
            order: index + 1,
            type: 6,
            body: {
              with_bg: true,
              bg_image: "",
              bg_size: "cover",
              subtitle_id: "",
              subtitle_en: "",
              title_id: "",
              title_en: "",
              chart_id: 0,
            },
          });
        el.type !== "divider" &&
          (finalBody = {
            ...finalBody,
            order: index + 1,
            body: {
              ...el.body,
            },
          });
        el.type === "divider" &&
          (finalBody = {
            ...finalBody,
            order: index + 1,
          });

        finalBody.body = JSON.stringify(finalBody.body);
        promises.push(elementActionHandler(finalBody, el.type, "create"));
      });

      toast.promise(Promise.all(promises), {
        pending: "Loading...",
        success: "Element berhasil dibuat!",
        error: "Element gagal dibuat!",
      });

      Promise.all(promises).then(() => {
        getPageData();
        setIsLoading(false);
      });
    }
    if (!isIdentical && oldElements.length > 0) {
      const promisesDelete = [];
      const promisesUpdate = [];
      const newElements = [];

      elements.filter((el) => {
        newElements.push(
          !oldElements.some((f) => {
            return f.id === el.id;
          }) === true && el
        );
      });
      newElements.filter(Boolean);

      // Remove deleted elements
      deletedElements.length > 0 &&
        deletedElements.forEach(async (el) => {
          promisesDelete.push(elementActionHandler(el, el.type, "delete"));
        });

      if (newElements.length === 0) {
        toast.promise(Promise.all(promisesDelete), {
          pending: "Loading...",
          success: "Element berhasil dihapus!",
          error: "Element gagal dihapus!",
        });

        Promise.all(promisesDelete).then(() => {
          getPageData();
          setIsLoading(false);
        });
      }

      if (newElements.length > 0) {
        Promise.all(promisesDelete).then(() => {
          // Get only modified elements
          const modifiedEl = elements.filter(
            (el) => !deletedElements.includes(el)
          );

          modifiedEl.forEach(async (el) => {
            el.type !== "divider" &&
              (finalBody = {
                type: el.type,
                section: el.section_id,
                page_id: el.page_id,
                id: el.default_id ? el.default_id : el.id,
                order: el.order,
                body: {
                  ...el.body,
                },
              });
            el.type === "divider" &&
              (finalBody = {
                type: el.type,
                section: el.section_id,
                page_id: el.page_id,
                id: el.default_id ? el.default_id : el.id,
                order: el.order,
              });
            el.type.includes("template") &&
              (finalBody = {
                ...finalBody,
                order: el.order,
                with_bg: true,
                bg_image: "",
                bg_size: "cover",
              });
            (el.type === "template-1" || el.default_type === "template-1") &&
              (finalBody = {
                ...finalBody,
                type: 1,
                body: {
                  with_bg: true,
                  bg_image: "",
                  bg_size: "cover",
                  title_id: finalBody.body?.title_id || "",
                  title_en: finalBody.body?.title_en || "",
                  dividers: true,
                  text_id: finalBody.body?.text_id || "",
                  text_en: finalBody.body?.text_en || "",
                  img: finalBody.body?.img || [],
                },
              });
            (el.type === "template-2" || el.default_type === "template-2") &&
              (finalBody = {
                ...finalBody,
                type: 2,
                body: {
                  with_bg: true,
                  bg_image: "",
                  bg_size: "cover",
                  subtitle_id: finalBody.body?.subtitle_id || "",
                  subtitle_en: finalBody.body?.subtitle_en || "",
                  title_id: finalBody.body?.title_id || "",
                  title_en: finalBody.body?.title_en || "",
                  dividers: true,
                  img: finalBody.body?.img || null,
                  youtube: finalBody.body?.youtube || null,
                },
              });
            (el.type === "template-3" || el.default_type === "template-3") &&
              (finalBody = {
                ...finalBody,
                type: 3,
                body: {
                  with_bg: true,
                  bg_image: "",
                  bg_size: "cover",
                  title_id: finalBody.body?.title_id || "",
                  title_en: finalBody.body?.title_en || "",
                  dividers: true,
                  text_id: finalBody.body?.text_id || "",
                  text_en: finalBody.body?.text_en || "",
                  img: finalBody.body?.img || [],
                },
              });
            (el.type === "template-4" || el.default_type === "template-4") &&
              (finalBody = {
                ...finalBody,
                type: 4,
                body: {
                  with_bg: true,
                  bg_image: "",
                  bg_size: "cover",
                  subtitle_id: finalBody.body?.subtitle_id || "",
                  subtitle_en: finalBody.body?.subtitle_en || "",
                  title_id: finalBody.body?.title_id || "",
                  title_en: finalBody.body?.title_en || "",
                  img: finalBody.body?.img || [],
                  list_en: finalBody.body?.list_id || [],
                  list_id: finalBody.body?.list_en || [],
                },
              });
            (el.type === "template-5" || el.default_type === "template-5") &&
              (finalBody = {
                ...finalBody,
                type: 5,
                body: {
                  with_bg: false,
                  bg_image: "",
                  bg_size: "cover",
                  subtitle_id: finalBody.body?.subtitle_id || "",
                  subtitle_en: finalBody.body?.subtitle_en || "",
                  title_id: finalBody.body?.title_id || "",
                  title_en: finalBody.body?.title_en || "",
                  chart_id: finalBody.body?.chart_id || 0,
                },
              });
            (el.type === "template-6" || el.default_type === "template-6") &&
              (finalBody = {
                ...finalBody,
                type: 6,
                body: {
                  with_bg: true,
                  bg_image: "",
                  bg_size: "cover",
                  subtitle_id: finalBody.body?.subtitle_id || "",
                  subtitle_en: finalBody.body?.subtitle_en || "",
                  title_id: finalBody.body?.title_id || "",
                  title_en: finalBody.body?.title_en || "",
                  chart_id: finalBody.body?.chart_id || 0,
                },
              });

            finalBody.body = JSON.stringify(finalBody.body);
            promisesUpdate.push(
              el?.default_id
                ? elementActionHandler(finalBody, el.type, "update")
                : elementActionHandler(finalBody, el.type, "create")
            );
          });

          toast.promise(Promise.all(promisesUpdate), {
            pending: "Loading...",
            success: "Element berhasil diupdate!",
            error: "Element gagal diupdate!",
          });

          Promise.all(promisesUpdate).then(() => {
            getPageData();
            setIsLoading(false);
          });
        });
      }
    }
  };

  const updateBodyElement = async (data) => {
    Object.entries(data).forEach(([key, value]) => {
      if (!value) delete data[key];
    });

    let finalBody = {
      id: oldData.default_id,
      order: oldData.order,
      body: {
        ...data,
      },
    };

    if (updateName.includes("template")) {
      finalBody = {
        ...finalBody,
        type: parseInt(updateName.split("-")[1]),
        body: {
          ...oldData?.body,
          ...finalBody.body,
        },
      };
      if (updateName === "template-2") {
        finalBody = {
          ...finalBody,
          body: {
            ...finalBody.body,
            img: null,
          },
        };
      }
      if (updateName === "template-4") {
        finalBody = {
          ...finalBody,
          body: {
            ...finalBody.body,
            list_en: finalBody.body["list_en"].split(/\r?\n/),
            list_id: finalBody.body["list_id"].split(/\r?\n/),
          },
        };
      }
    }

    if (updateName === "list") {
      if (!listType) {
        toast.error("Tipe list harus dipilih!");
        return;
      }

      finalBody = {
        ...finalBody,
        body: {
          title_id: finalBody.body["title_id"],
          title_en: finalBody.body["title_en"],
          en: finalBody.body["en"].split(/\r?\n/),
          id: finalBody.body["id"].split(/\r?\n/),
          type: listType,
        },
      };
    }
    if (file) {
      setIsUploading(true);

      const imgBody = {
        images: [file],
      };

      try {
        const res = await uploadImages(imgBody, setProgress);
        const img = res.data.data.data[0];

        finalBody.body = {
          ...finalBody.body,
          img: img,
        };

        setFile(undefined);
        setIsUploading(false);
        onClose();
      } catch (error) {
        console.error(error);
        toast.error("Gambar gagal diupload!");
        setIsUploading(false);
        onClose();
      }
    } else if (
      tmplt1Files.length > 0 ||
      tmplt2Files.length > 0 ||
      tmplt3Files.length > 0 ||
      tmplt4Files.length > 0
    ) {
      setIsUploading(true);

      let imgBody = {
        images: [],
      };

      if (tmplt1Files.length > 0) {
        imgBody = {
          images: tmplt1Files,
        };
      } else if (tmplt2Files.length > 0)
        imgBody = {
          images: tmplt2Files,
        };
      else if (tmplt3Files.length > 0) {
        imgBody = {
          images: tmplt3Files,
        };
      } else if (tmplt4Files.length > 0) {
        imgBody = {
          images: tmplt4Files,
        };
      }

      try {
        const res = await uploadImages(imgBody, setProgress);

        if (tmplt1Files.length > 0)
          finalBody = {
            ...finalBody,
            type: 1,
            body: {
              with_bg: true,
              bg_image: "",
              bg_size: "cover",
              dividers: true,
              ...finalBody.body,
              img: res.data.data.data,
            },
          };
        else if (tmplt2Files.length > 0)
          finalBody = {
            ...finalBody,
            type: 2,
            body: {
              with_bg: true,
              bg_image: "",
              bg_size: "cover",
              dividers: true,
              ...finalBody.body,
              img: res.data.data.data,
              youtube: null,
            },
          };
        else if (tmplt3Files.length > 0)
          finalBody = {
            ...finalBody,
            type: 3,
            body: {
              with_bg: true,
              bg_image: "",
              bg_size: "cover",
              dividers: true,
              ...finalBody.body,
              img: res.data.data.data,
            },
          };
        else if (tmplt4Files.length > 0)
          finalBody = {
            ...finalBody,
            type: 4,
            body: {
              with_bg: false,
              bg_image: "",
              bg_size: "cover",
              ...finalBody.body,
              img: res.data.data.data,
            },
          };

        setIsUploading(false);
        onClose();
      } catch (error) {
        console.error(error);
        toast.error("Gambar gagal diupload!");
        setIsUploading(false);
        onClose();
      }
    } else if (existingImages)
      finalBody.body = {
        ...finalBody.body,
        img: existingImages,
      };

    finalBody.body = JSON.stringify(finalBody.body);
    const promise = elementActionHandler(finalBody, updateName, "update");

    toast.promise(promise, {
      pending: "Loading...",
      success: "Data berhasil diperbarui!",
      error: "Data gagal diperbarui!",
    });

    promise
      .then(() => {
        handleCloseModal();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Youtube Video Handler
  const handleInputVideo = async (e) => {
    let youtubeUrl = typeof e === "string" ? e : e.target.value;
    let youtubeId = getYoutubeID(youtubeUrl);

    if (youtubeId !== "") {
      const newPreview = await getVideoPreviewData(youtubeId);
      const { runtime, youtube_video_id } = newPreview;

      if (videos.length === 0) {
        setThumbnails([...thumbnails, newPreview?.thumbnailHigh]);
        setPreview([...preview, newPreview]);
        setVideos([...videos, runtime, youtube_video_id]);
      } else {
        // Check if video already exist in the state
        if (
          videos?.find((video) => video.youtube_video_id === youtubeId) ===
          undefined
        ) {
          setThumbnails([...thumbnails, newPreview?.thumbnailHigh]);
          setPreview([...preview, newPreview]);
          setVideos([...videos, runtime, youtube_video_id]);
        }
      }
      typeof e === "string" ? (e = "") : (e.target.value = "");
    }
  };

  const handleDeleteVideo = (index) => {
    const newPreview = preview.filter((_preview, i) => i !== index);
    const newVideos = videos.filter((_video, i) => i !== index);
    const newThumbnails = thumbnails.filter((_thumbnail, i) => i !== index);
    setPreview(newPreview);
    setVideos(newVideos);
    setThumbnails(newThumbnails);
    reset({
      youtube: null,
    });
  };
  // End Youtube Video Handler

  const handleCloseModal = () => {
    getPageData();
    setFile(undefined);
    setTmplt1Files([]);
    setTmplt2Files([]);
    setTmplt3Files([]);
    setTmplt4Files([]);
    setProgress(0);
    setExistingImages([]);
    setListType(undefined);
    setVideos([]);
    setThumbnails([]);
    setPreview([]);
    reset({});
    onClose();
  };

  const getPageData = async () => {
    const pathname = location.pathname
      .split("/")[2]
      .toLowerCase()
      .replace(/ /g, "-");
    const allPageData = Promise.resolve(getAllPageContent());
    allPageData
      .then((res) => {
        const targetPage = res.data.data.find(
          (el) => el.name.toLowerCase().replace(/ /g, "-") === pathname
        );
        getPageContent(targetPage?.id)
          .then((res) => {
            setPageData(res.data.data);
            refactorElements(res.data.data.contents);
          })
          .catch((err) => {
            console.error(err);
            toast.error("Gagal mengambil data halaman!");
          });
      })
      .catch((err) => {
        console.error(err);
        toast.error("Gagal mengambil data halaman!");
      });
  };

  React.useEffect(() => {
    getPageData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ElementsList elements={elementTypes} handleClick={addElement} />
      <LockedLayout
        title={pageData?.name ?? "Page Builder"}
        description={
          "Drag & drop element di bawah untuk mengatur urutan element."
        }
        items={elements}
        sensors={sensors}
        arrangeItems={arrangeElement}
        rightCTA={true}
        isCTALoading={isLoading}
        CTACopy="Simpan Layout"
        onClick={handleSubmit(onSubmit)}
        disabled={
          JSON.stringify(oldElements) === JSON.stringify(elements) || isLoading
        }
        updateItemUrl={
          JSON.stringify(oldElements) === JSON.stringify(elements)
            ? updateElementBody
            : undefined
        }
        handleRemoveItem={removeElement}
      />
      <BasicModal
        isOpen={isOpen}
        onClose={() => handleCloseModal()}
        header="Update Data"
        scrollBehavior="inside"
        size="4xl"
      >
        {isUploading ? (
          progress !== 100 ? (
            <>
              <div className="text-center">
                <p className="mb-4 text-lg font-semibold text-navy-700">
                  Mengupload gambar ...
                </p>
                <h3 className="mb-3 text-3xl font-bold text-gray-700">
                  {progress}%
                </h3>
              </div>
              <div className="relative h-4 w-full overflow-hidden rounded-2xl bg-gray-300">
                <div
                  className="absolute top-0 left-0 h-full bg-brand-500 
                        transition-all duration-500 ease-linear"
                  style={{
                    width: `${progress}%`,
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <p className="text-center text-lg font-semibold text-navy-700">
                Menyimpan data ...
              </p>
            </>
          )
        ) : (
          <form
            encType="multipart/form-data"
            onSubmit={handleSubmit(updateBodyElement)}
          >
            {updateName === "image" && (
              <DropzoneFile
                text="Upload file"
                accept={{
                  "image/jpeg": [".jpg", ".jpeg", ".png"],
                  "image/svg+xml": [".svg"],
                }}
                dropFile={dropFile}
                removeFile={removeFile}
                file={file}
              />
            )}
            {(updateName === "text" || updateName === "description") && (
              <Text
                updateName={updateName}
                errors={errors}
                register={register}
              />
            )}
            {(updateName === "title" || updateName === "subtitle") && (
              <Title
                updateName={updateName}
                errors={errors}
                register={register}
              />
            )}
            {updateName === "link" && (
              <Link
                updateName={updateName}
                errors={errors}
                register={register}
              />
            )}{" "}
            {updateName === "list" && (
              <List
                errors={errors}
                register={register}
                listType={listType}
                setListType={(type) => {
                  setListType(type);
                }}
              />
            )}
            {updateName === "member-card" && (
              <MemberCards
                errors={errors}
                register={register}
                dropImage={dropFile}
                removeImage={removeFile}
                file={file}
                existingImages={existingImages[0]}
              />
            )}
            {updateName === "page-image" && (
              <SingleImage
                errors={errors}
                register={register}
                dropFile={dropFile}
                removeFile={removeFile}
                existingImage={existingImages[0]}
                file={file}
              />
            )}
            {updateName === "template-1" && (
              <Template1
                errors={errors}
                register={register}
                dropFile={dropFile}
                removeFile={removeFile}
                existingImages={existingImages}
                files={tmplt1Files}
              />
            )}
            {updateName === "template-2" && (
              <Template2
                errors={errors}
                register={register}
                dropFile={dropFile}
                removeFile={removeFile}
                existingImages={existingImages}
                file={tmplt2Files}
                handleDeleteVideo={handleDeleteVideo}
                handleInputVideo={handleInputVideo}
                preview={preview}
                thumbnails={thumbnails}
              />
            )}
            {updateName === "template-3" && (
              <Template3
                errors={errors}
                register={register}
                dropFile={dropFile}
                removeFile={removeFile}
                existingImages={existingImages}
                files={tmplt3Files}
              />
            )}
            {updateName === "template-4" && (
              <Template4
                errors={errors}
                register={register}
                dropFile={dropFile}
                removeFile={removeFile}
                existingImages={existingImages}
                files={tmplt4Files}
              />
            )}
            {updateName === "template-5" && (
              <Template5
                errors={errors}
                register={register}
                handleChart={handleExtendedChart}
              />
            )}
            {updateName === "template-6" && (
              <Template6
                errors={errors}
                register={register}
                handleChart={handleExtendedChart}
              />
            )}
            {/* Button Submit & Close */}
            <div className="float-right mt-8 flex gap-3">
              <button
                className="linear rounded-[20px] bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                type="submit"
              >
                Update
              </button>
              <button
                className="linear rounded-[20px] bg-white px-4 py-2 text-base font-semibold text-gray-600 transition duration-200 dark:bg-transparent dark:text-white dark:opacity-90 dark:active:opacity-90"
                onClick={() => handleCloseModal()}
                type="button"
              >
                Batal
              </button>
            </div>
          </form>
        )}
      </BasicModal>
    </>
  );
};

export default LayoutCanvas;
