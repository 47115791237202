import axiosInstance from "./config";

export const createList = (body) => {
  return axiosInstance.post("/lists", body);
};

export const updateList = (id, body) => {
  return axiosInstance.post(`/lists/update/${id}`, body);
};

export const deleteList = (id) => {
  return axiosInstance.get(`/lists/delete/${id}`);
};
