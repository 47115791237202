import axiosInstance from "./config";

export const createCorporateGovernance = (body) => {
  return axiosInstance.post("/information-openness", body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateCorporateGovernance = (id, body) => {
  return axiosInstance.post(`/information-openness/update/${id}`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const showAllCorporateGovernance = () => {
  return axiosInstance.get(`/information-openness`);
};

export const showCorporateGovernance = (id) => {
  return axiosInstance.get(`/information-openness/${id}`);
};

export const deleteCorporateGovernance = (id) => {
  return axiosInstance.get(`/information-openness/delete/${id}`);
};
