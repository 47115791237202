import axiosInstance from "./config";

// Commissioner API
export const showAllCommissioner = () => {
  return axiosInstance.get("/commisioners");
};

export const showCommissioner = (id) => {
  return axiosInstance.get(`/commisioners/${id}`);
};

export const createCommissioner = (body) => {
  return axiosInstance.post("/commisioners", body);
};

export const deleteCommissioner = (id) => {
  return axiosInstance.get(`/commisioners/delete/${id}`);
};

export const updateCommissioner = (id, body) => {
  return axiosInstance.post(`/commisioners/update/${id}`, body);
};

// Director API
export const showAllDirector = () => {
  return axiosInstance.get("/directors");
};

export const showDirector = (id) => {
  return axiosInstance.get(`/directors/${id}`);
};

export const createDirector = (body) => {
  return axiosInstance.post("/directors", body);
};

export const deleteDirector = (id) => {
  return axiosInstance.get(`/directors/delete/${id}`);
};

export const updateDirector = (id, body) => {
  return axiosInstance.post(`/directors/update/${id}`, body);
};
