import axiosInstance from "./config";

export const createFooter = (body) => {
  return axiosInstance.post("/footer", body);
};

export const updateFooter = (body) => {
  return axiosInstance.post(`/footer/update`, body);
};

export const showFooter = () => {
  return axiosInstance.get(`/footer`);
};

export const deleteFooter = (id) => {
  return axiosInstance.get(`/footer/delete/${id}`);
};
