import axiosInstance from "./config";

export const createChart = (body) => {
  return axiosInstance.post("/charts", body);
};

export const showChart = (id) => {
  return axiosInstance.get(`/charts/${id}`);
};

export const updateChart = (id, body) => {
  return axiosInstance.post(`/charts/update/${id}`, body);
};

export const deleteChart = (id) => {
  return axiosInstance.get(`/charts/delete/${id}`);
};
