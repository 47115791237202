import axiosInstance from "./config";

export const showAllCareer = () => {
  return axiosInstance.get("/career");
};

export const showCareer = (id) => {
  return axiosInstance.get(`/career/${id}`);
};

export const createCareer = (body) => {
  return axiosInstance.post("/career", body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteCareer = (id) => {
  return axiosInstance.get(`/career/delete/${id}`);
};

export const updateCareer = (id, body) => {
  return axiosInstance.post(`/career/update/${id}`, body);
};
