import DropzoneFile from "components/dropzone/DropzoneFile";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import React from "react";

const Template3 = (props) => {
  const { register, errors, existingImages, dropFile, removeFile, files } =
    props;

  return (
    <div className="flex flex-col items-start justify-between gap-3">
      <div className="flex w-full flex-1 flex-col gap-5">
        <div className="flex flex-col gap-3">
          <InputField
            extra="mb-3"
            label={`Title (EN)*`}
            id={`title_en`}
            type="text"
            name={`title_en`}
            errors={errors}
            register={register}
            defaultValue={""}
            validationSchema={{
              required: `Title (EN) harus diisi!`,
            }}
          />
          <InputField
            extra="mb-3"
            label={`Title (ID)*`}
            id={`title_id`}
            type="text"
            name={`title_id`}
            errors={errors}
            register={register}
            defaultValue={""}
            validationSchema={{
              required: `Title (ID) harus diisi!`,
            }}
          />
        </div>
        <div className="flex w-full flex-1 gap-5">
          <TextField
            extra="mb-3 w-full"
            label={`Text (EN)*`}
            id={`text_en`}
            type="text"
            name={`text_en`}
            errors={errors}
            register={register}
            rows={10}
            defaultValue={""}
            validationSchema={{
              required: `Text (EN) harus diisi!`,
            }}
          />
          <TextField
            extra="mb-3 w-full"
            label={`Text (ID)*`}
            id={`text_id`}
            type="text"
            name={`text_id`}
            errors={errors}
            register={register}
            rows={10}
            defaultValue={""}
            validationSchema={{
              required: `Text (ID) harus diisi!`,
            }}
          />
        </div>
      </div>
      <div className="flex flex-1 flex-col">
        <div className="flex gap-5">
          {existingImages?.length > 0 &&
            existingImages?.map((image, index) => (
              <div
                className="relative m-3 my-3 flex w-full max-w-xs items-center justify-center rounded-xl border-2 border-gray-200"
                key={index}
              >
                <img
                  src={
                    image === undefined
                      ? "https://via.placeholder.com/120x100?text=No+Image"
                      : process.env.REACT_APP_API_ENDPOINT + "/images/" + image
                  }
                  alt={
                    image !== undefined &&
                    process.env.REACT_APP_API_ENDPOINT + "/images/" + image
                  }
                  className="rounded-lg"
                />
              </div>
            ))}
        </div>
        <div className="flex w-full items-center gap-3">
          <div className="flex flex-1 gap-5">
            <DropzoneFile
              text="Upload file"
              accept={{
                "image/jpeg": [".jpg", ".jpeg", ".png"],
                "image/svg+xml": [".svg"],
              }}
              dropFile={dropFile}
              removeFile={removeFile}
              file={files[0]}
            />
            <DropzoneFile
              text="Upload file"
              accept={{
                "image/jpeg": [".jpg", ".jpeg", ".png"],
                "image/svg+xml": [".svg"],
              }}
              dropFile={dropFile}
              removeFile={removeFile}
              file={files[1]}
            />
            <DropzoneFile
              text="Upload file"
              accept={{
                "image/jpeg": [".jpg", ".jpeg", ".png"],
                "image/svg+xml": [".svg"],
              }}
              dropFile={dropFile}
              removeFile={removeFile}
              file={files[2]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Template3;
