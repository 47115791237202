import React, { useEffect, useState } from "react";
import CardContainer from "components/card/CardContainer";
import Widget from "components/widget/Widget";
import { MdDataArray, MdShare } from "react-icons/md";
import BasicModal from "components/modal/BasicModal";
import { useDisclosure } from "@chakra-ui/hooks";
import InputField from "components/fields/InputField";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { getPageContent } from "api/PageAPI";
import { updateSubtitle } from "api/SubtitleAPI";
import { updateTitle } from "api/TitleAPI";
import Skeleton from "react-loading-skeleton";
import { showAllCommissioner } from "api/LeadershipAPI";
import { showAllDirector } from "api/LeadershipAPI";
import { showAllShareholder } from "api/ShareholderAPI";

const LeadershipWidgets = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const pageId = 6;
  const [updateName, setUpdateName] = useState("");
  const [subtitleId, setSubtitleId] = useState(null);
  const [titleId, setTitleId] = useState(null);
  const [content, setContent] = useState({
    loading: true,
    data: null,
    shareholders: null,
    commissioners: null,
    directors: null,
  });
  const payload = {
    page_id: pageId,
    order:
      content.data?.contents.findIndex((item) => item.name === updateName) + 1,
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const showUpdateModal = (name) => {
    setUpdateName(name);
    setSubtitleId(getElementId("subtitle"));
    setTitleId(getElementId("title"));

    const tempReset = content?.data?.contents.find(
      (item) => item.name === name
    )?.body;

    reset({
      en: tempReset.en,
      id: tempReset.id,
    });

    onOpen();
  };

  const onSubmit = (data) => {
    const finalBody = {
      ...payload,
      body: {
        ...data,
      },
    };

    let promise = null;

    finalBody.body = JSON.stringify(finalBody.body);

    // Update Data Only
    updateName === "subtitle" &&
      (promise = updateSubtitle(subtitleId, finalBody));
    updateName === "title" && (promise = updateTitle(titleId, finalBody));

    toast.promise(promise, {
      pending: "Loading...",
      success: "Berhasil di-update!",
      error: "Gagal meng-update data!",
    });

    reset();
    onClose();

    promise
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          getContent();
          onClose();
          reset();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const getElementId = (name) => {
    const temp = content?.data?.contents?.find((item) => item.name === name);
    return temp?.id;
  };

  const getLeadershipData = (data) => {
    showAllShareholder()
      .then((shareholder) => {
        showAllCommissioner()
          .then((commissioner) => {
            showAllDirector()
              .then((director) => {
                setContent({
                  loading: false,
                  data: data,
                  shareholders: shareholder.data.data,
                  commissioners: commissioner.data.data,
                  directors: director.data.data,
                });
              })
              .catch((err) => {
                if (err.response.status !== 200)
                  toast.error("Data Direktur gagal dimuat!");
              });
          })
          .catch((err) => {
            if (err.response.status !== 200)
              toast.error("Data Komisioner gagal dimuat!");
          });
      })
      .catch((err) => {
        if (err.response.status !== 200)
          toast.error("Data Shareholder gagal dimuat!");
      });
  };

  const getContent = () => {
    getPageContent(pageId).then((res) => {
      getLeadershipData(res.data.data);
    });
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
    <>
      <CardContainer
        title="Leadership Widgets"
        description="Atur element yang ada pada halaman leadership di sini!"
      >
        <div className="flex flex-col gap-3">
          {content.loading ? (
            <>
              <Skeleton height={45} className="w-full rounded-xl" />
              <Skeleton height={45} className="w-full rounded-xl" />
            </>
          ) : (
            <>
              {/* Enable this to show title & subtitle editor */}
              {/* {content?.data?.contents?.length > 0 &&
                content?.data?.contents?.map((item, index) => {
                  return (
                    <div key={index}>
                      <Widget
                        title={item.name}
                        subtitle={item.body.id}
                        icon={
                          item.name === "subtitle" ? (
                            <MdShortText />
                          ) : item.name === "title" ? (
                            <MdTextFields />
                          ) : (
                            <MdWidgets />
                          )
                        }
                        updateUrl={() => showUpdateModal(item.name)}
                      />
                    </div>
                  );
                })} */}
              <Widget
                title="Shareholders"
                subtitle={
                  content?.shareholders?.length > 0
                    ? content?.shareholders?.slice(0, 5).map((item, index) => {
                        if (index < 4) return item?.body?.name + ", ";
                        else return " dsb";
                      })
                    : "-"
                }
                icon={<MdShare />}
                viewUrl="shareholders"
              />
              <Widget
                title="Direktur"
                subtitle={
                  content?.directors?.length > 0
                    ? content?.directors?.slice(0, 5).map((item, index) => {
                        if (index < 4) return item?.body?.name + ", ";
                        else return " dsb";
                      })
                    : "-"
                }
                icon={<MdDataArray />}
                viewUrl="directors"
              />
              <Widget
                title="Komisioner"
                subtitle={
                  content?.commissioners?.length > 0
                    ? content?.commissioners?.slice(0, 5).map((item, index) => {
                        if (index < 4) return item?.body?.name + ", ";
                        else return " dsb";
                      })
                    : "-"
                }
                icon={<MdDataArray />}
                viewUrl="commissioners"
              />
            </>
          )}
        </div>
      </CardContainer>
      <BasicModal isOpen={isOpen} onClose={onClose} header="Update Data">
        <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
          {
            <>
              <InputField
                extra="mb-3"
                label={`${
                  updateName.charAt(0).toUpperCase() + updateName.slice(1)
                } (EN)*`}
                id={`en`}
                type="text"
                name={`en`}
                errors={errors}
                register={register}
                defaultValue={""}
                validationSchema={{
                  required: `${
                    updateName.charAt(0).toUpperCase() + updateName.slice(1)
                  } (EN) harus diisi`,
                }}
              />
              <InputField
                extra="mb-3"
                label={`${
                  updateName.charAt(0).toUpperCase() + updateName.slice(1)
                } (ID)*`}
                id={`id`}
                type="text"
                name={`id`}
                errors={errors}
                register={register}
                defaultValue={""}
                validationSchema={{
                  required: `${
                    updateName.charAt(0).toUpperCase() + updateName.slice(1)
                  } (ID) harus diisi`,
                }}
              />
            </>
          }
          {/* Button Submit & Close */}
          <div className="float-right mt-8 flex gap-3">
            <button
              className="linear rounded-[20px] bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
              type="submit"
            >
              Update
            </button>
            <button
              className="linear rounded-[20px] bg-white px-4 py-2 text-base font-semibold text-gray-600 transition duration-200 dark:bg-transparent dark:text-white dark:opacity-90 dark:active:opacity-90"
              onClick={() => {
                reset();
                onClose();
              }}
              type="button"
            >
              Batal
            </button>
          </div>
        </form>
      </BasicModal>
    </>
  );
};

export default LeadershipWidgets;
