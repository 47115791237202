// Admin Imports
import LayoutCanvas from "views/admin/canvas/LayoutCanvas";
import Charts from "views/admin/canvas/components/Chart";
import ImageLists from "views/admin/canvas/components/ImageList";
import MapChart from "views/admin/canvas/components/MapChart";
import HomePage from "views/admin/home";
import HomeAreaWidgets from "views/admin/home/data/CoverageArea/HomeAreaWidgets";
import RegionsData from "views/admin/home/data/CoverageArea/RegionsData";
import HomeAboutData from "views/admin/home/data/HomeAboutData";
import HomeCarouselData from "views/admin/home/data/HomeCarouselData";
import HomeFacilitiesData from "views/admin/home/data/HomeFacilitiesData";
import HomeLogoCarouselData from "views/admin/home/data/HomeLogoCarouselData";
import HomeNewsData from "views/admin/home/data/HomeNewsData";
import HomeProductData from "views/admin/home/data/HomeProductData";
import HomeSocialData from "views/admin/home/data/SocialMedia/HomeSocialData";
import YoutubeData from "views/admin/home/data/SocialMedia/YoutubeData";
import HomeAboutForm from "views/admin/home/forms/HomeAboutForm";
import HomeCarouselForm from "views/admin/home/forms/HomeCarouselForm";
import HomeLogoCarouselForm from "views/admin/home/forms/HomeLogoCarouselForm";
import HomeYoutubeForm from "views/admin/home/forms/HomeYoutubeForm";
import LeadershipWidgets from "views/admin/leadership/LeadershipWidgets";
import Commissioner from "views/admin/leadership/pages/commissioner";
import Director from "views/admin/leadership/pages/director";
import Shareholder from "views/admin/leadership/pages/shareholder";
import InternalAuditData from "views/admin/management/internal-audit/InternalAuditData";
import InternalAuditWidgets from "views/admin/management/internal-audit/InternalAuditWidgets";
import CareerData from "views/admin/news/pages/career/CareerData";
import CompanyActivitiesData from "views/admin/news/pages/company_activities/CompanyActivitiesData";
import CSRData from "views/admin/news/pages/csr/CSRData";
import AllProduct from "views/admin/product/pages/all-product";
import {
  default as ProductCanvas,
  default as ProductLayout,
} from "views/admin/product/pages/all-product/ProductCanvas";
import ProductCardLists from "views/admin/product/pages/all-product/ProductCardLists";
import ProductCards from "views/admin/product/pages/all-product/ProductCards";
import ProductTables from "views/admin/product/pages/all-product/ProductTables";
import ProductTextBoxes from "views/admin/product/pages/all-product/ProductTextBoxes";
import CertificateData from "views/admin/product/pages/certificate/CertificateData";
import MilestoneData from "views/admin/profile/pages/milestone/MilestoneData";
import MilestoneWidgets from "views/admin/profile/pages/milestone/MilestoneWidgets";
import Affiliate from "views/admin/resource/pages/Affiliate";
import Customer from "views/admin/resource/pages/Customer";
import Supplier from "views/admin/resource/pages/Supplier";
import Footer from "views/admin/website-settings/Footer";
import Logo from "views/admin/website-settings/Logo";

// Auth Imports
import Login from "views/auth/Login";

// Icon Imports
import {
  MdAccountCircle,
  MdAccountTree,
  MdFlag,
  MdFolderShared,
  MdHomeWork,
  MdLock,
  MdNewspaper,
  MdPropane,
  MdSettings,
  MdWork,
} from "react-icons/md";
import { RiLayoutBottom2Fill } from "react-icons/ri";
import CorporateGovernanceData from "views/admin/news/pages/corporate-governance/CorporateGovernanceData";

const routes = [
  // {
  //   id: 1,
  //   name: "Dashboard",
  //   type: "link",
  //   layout: "",
  //   path: "",
  //   icon: <MdDashboard className="h-6 w-6" />,
  //   component: <MainDashboard />,
  // },
  {
    id: 2,
    name: "Login",
    type: "link",
    layout: "/auth",
    path: "login",
    icon: <MdLock className="h-6 w-6" />,
    component: <Login />,
  },
  // {
  //   id: 3,
  //   name: "Register",
  //   type: "link",
  //   layout: "/auth",
  //   path: "register",
  //   icon: <MdLock className="h-6 w-6" />,
  //   component: <Register />,
  // },
  {
    id: 4,
    name: "Home Page",
    type: "link",
    layout: "",
    path: "home-page",
    icon: <MdHomeWork className="h-6 w-6" />,
    component: <HomePage />,
  },
  // Home Page
  // Carousel
  {
    id: 5,
    name: "Carousel",
    type: "link",
    layout: "/tables",
    path: "home-page/carousel",
    icon: "",
    component: <HomeCarouselData />,
  },
  {
    id: 6,
    name: "Tambah Carousel",
    type: "link",
    layout: "/forms",
    path: "home-page/carousel/add/section/:sectionId",
    icon: "",
    component: <HomeCarouselForm />,
  },
  {
    id: 7,
    name: "Carousel Update",
    type: "link",
    layout: "/forms",
    path: "home-page/carousel/update/section/:sectionId/item/:itemId",
    icon: "",
    component: <HomeCarouselForm />,
  },
  // End Carousel
  // Logo Carousel
  {
    id: 8,
    name: "Carousel Logo",
    type: "link",
    layout: "/tables",
    path: "home-page/logo-carousel",
    icon: "",
    component: <HomeLogoCarouselData />,
  },
  {
    id: 9,
    name: "Tambah Carousel",
    type: "link",
    layout: "/forms",
    path: "home-page/logo-carousel/add/section/:sectionId",
    icon: "",
    component: <HomeLogoCarouselForm />,
  },
  {
    id: 10,
    name: "Carousel Update",
    type: "link",
    layout: "/forms",
    path: "home-page/logo-carousel/update/section/:sectionId/item/:itemId",
    icon: "",
    component: <HomeLogoCarouselForm />,
  },
  // End Logo Carousel
  // Short About
  {
    id: 11,
    name: "Tentang Kami",
    type: "link",
    layout: "/tables",
    path: "home-page/short-about",
    icon: "",
    component: <HomeAboutData />,
  },
  {
    id: 12,
    name: "Update Tentang Kami",
    type: "link",
    layout: "/forms",
    path: "home-page/short-about/add",
    icon: "",
    component: <HomeAboutForm />,
  },
  // End Short About
  // Short About
  {
    id: 13,
    name: "Area Jangkauan",
    type: "link",
    layout: "/tables",
    path: "home-page/coverage-area",
    icon: "",
    component: <HomeAreaWidgets />,
  },
  {
    id: 14,
    name: "Data Area Jangkauan",
    type: "link",
    layout: "/tables",
    path: "home-page/coverage-area/regions",
    icon: "",
    component: <RegionsData />,
  },
  // End Short About
  // Short About
  {
    id: 15,
    name: "Fasilitas",
    type: "link",
    layout: "/tables",
    path: "home-page/short-facilities",
    icon: "",
    component: <HomeFacilitiesData />,
  },
  // End Short About
  // Product Data
  {
    id: 16,
    name: "Produk",
    type: "link",
    layout: "/tables",
    path: "home-page/products",
    icon: "",
    component: <HomeProductData />,
  },
  // End Product Data
  // Company News
  {
    id: 17,
    name: "Berita Perusahaan",
    type: "link",
    layout: "/tables",
    path: "home-page/company-news",
    icon: "",
    component: <HomeNewsData />,
  },
  // End Company News
  // Social Media
  {
    id: 18,
    name: "Media Sosial",
    type: "link",
    layout: "/tables",
    path: "home-page/social-media",
    icon: "",
    component: <HomeSocialData />,
  },
  {
    id: 19,
    name: "Youtube Video",
    type: "link",
    layout: "/tables",
    path: "home-page/youtube",
    icon: "",
    component: <YoutubeData />,
  },
  {
    id: 20,
    name: "Youtube Video",
    type: "link",
    layout: "/tables",
    path: "home-page/youtube/add",
    icon: "",
    component: <HomeYoutubeForm />,
  },
  // End Social Media
  // End Home Page
  // Profile Pages
  {
    id: 21,
    name: "Profile Pages",
    type: "accordion",
    layout: "",
    path: "/profile-pages",
    icon: <MdAccountCircle className="h-6 w-6" />,
    component: "",
    children: [
      {
        name: "Milestone",
        type: "link",
        layout: "",
        path: "profile-pages/milestone",
        component: <MilestoneWidgets />,
      },
      {
        name: "Vision & Mission",
        type: "link",
        layout: "",
        path: "profile-pages/vision-mission",
        component: <LayoutCanvas />,
      },
      {
        name: "Export",
        type: "link",
        layout: "",
        path: "profile-pages/export",
        component: <LayoutCanvas />,
      },
      {
        name: "Domestic",
        type: "link",
        layout: "",
        path: "profile-pages/domestic",
        component: <LayoutCanvas />,
      },
    ],
  },
  {
    id: 22,
    name: "Milestone Years",
    type: "link",
    layout: "/tables",
    path: "profile-pages/milestone/years",
    icon: "",
    component: <MilestoneData />,
  },
  {
    id: 23,
    name: "Image List",
    type: "link",
    layout: "/forms",
    path: "profile-pages/:slug/image-lists/:default_id",
    icon: "",
    component: <ImageLists />,
  },
  {
    id: 25,
    name: "Chart",
    type: "link",
    layout: "/forms",
    path: "profile-pages/:slug/charts/:default_id",
    icon: "",
    component: <Charts />,
  },
  {
    id: 26,
    name: "Map Chart",
    type: "link",
    layout: "/forms",
    path: "profile-pages/:slug/map-charts/:default_id",
    icon: "",
    component: <MapChart />,
  },
  {
    id: 27,
    name: "Section",
    type: "link",
    layout: "/forms",
    path: "profile-pages/:slug/section/:id",
    icon: "",
    component: <Charts />,
  },
  // End Profile Pages
  // Management Pages
  {
    id: 28,
    name: "Management Pages",
    type: "accordion",
    layout: "",
    path: "/management-pages",
    icon: <MdFolderShared className="h-6 w-6" />,
    component: "",
    children: [
      {
        name: "Business Committee",
        type: "link",
        layout: "",
        path: "management-pages/business-committee",
        component: <LayoutCanvas />,
      },
      {
        name: "Internal Audit & Comp. Secretarial",
        type: "link",
        layout: "",
        path: "management-pages/internal-audit-comp-secretarial",
        component: <InternalAuditWidgets />,
      },
      {
        name: "Risk Management",
        type: "link",
        layout: "",
        path: "management-pages/risk-management",
        component: <LayoutCanvas />,
      },
      {
        name: "Whistleblowing System",
        type: "link",
        layout: "",
        path: "management-pages/whistleblowing-system",
        component: <LayoutCanvas />,
      },
    ],
  },
  {
    id: 29,
    name: "Internal Audit & Corporate Secretary",
    type: "link",
    layout: "/tables",
    path: "management-pages/internal-audit-comp-secretarial/personnel",
    icon: "",
    component: <InternalAuditData />,
  },
  {
    id: 30,
    name: "Image List",
    type: "link",
    layout: "/forms",
    path: "management-pages/:slug/image-lists/:default_id",
    icon: "",
    component: <ImageLists />,
  },
  {
    id: 31,
    name: "Member Card",
    type: "link",
    layout: "/forms",
    path: "management-pages/:slug/charts/:default_id",
    icon: "",
    component: <Charts />,
  },
  {
    id: 32,
    name: "Map Chart",
    type: "link",
    layout: "/forms",
    path: "management-pages/:slug/map-charts/:default_id",
    icon: "",
    component: <MapChart />,
  },
  {
    id: 33,
    name: "Section",
    type: "link",
    layout: "/forms",
    path: "management-pages/:slug/section/:id",
    icon: "",
    component: <Charts />,
  },
  // End Management Pages
  // Leadership
  {
    id: 34,
    name: "Leadership",
    type: "link",
    layout: "",
    path: "leadership",
    icon: <MdAccountTree className="h-6 w-6" />,
    component: <LeadershipWidgets />,
  },
  {
    id: 35,
    name: "Shareholders",
    type: "link",
    layout: "/tables",
    path: "leadership/shareholders",
    icon: "",
    component: <Shareholder />,
  },
  {
    id: 36,
    name: "Dewan Komisaris",
    type: "link",
    layout: "/tables",
    path: "leadership/commissioners",
    icon: "",
    component: <Commissioner />,
  },
  {
    id: 37,
    name: "Dewan Direksi",
    type: "link",
    layout: "/tables",
    path: "leadership/directors",
    icon: "",
    component: <Director />,
  },
  // End Leadership
  {
    id: 38,
    name: "Resources Pages",
    type: "accordion",
    layout: "",
    path: "/resources-pages",
    icon: <MdFlag className="h-6 w-6" />,
    component: "",
    children: [
      {
        name: "Main Supplier",
        type: "link",
        layout: "",
        path: "resources-pages/main-supplier",
        component: <Supplier />,
      },
      {
        name: "Customers",
        type: "link",
        layout: "",
        path: "resources-pages/customers",
        component: <Customer />,
      },
      {
        name: "Affiliates",
        type: "link",
        layout: "",
        path: "resources-pages/affiliates",
        component: <Affiliate />,
      },
    ],
  },
  {
    id: 39,
    name: "Member Card",
    type: "link",
    layout: "/forms",
    path: "resources-pages/:slug/charts/:default_id",
    icon: "",
    component: <Charts />,
  },
  {
    id: 40,
    name: "Map Chart",
    type: "link",
    layout: "/forms",
    path: "resources-pages/:slug/map-charts/:default_id",
    icon: "",
    component: <MapChart />,
  },
  {
    id: 41,
    name: "Section",
    type: "link",
    layout: "/forms",
    path: "resources-pages/:slug/section/:id",
    icon: "",
    component: <Charts />,
  },
  // End Resources Pages
  // Products Pages
  {
    id: 42,
    name: "Products Pages",
    type: "accordion",
    layout: "",
    path: "/products-pages",
    icon: <MdPropane className="h-6 w-6" />,
    component: "",
    children: [
      {
        name: "All Products",
        type: "link",
        layout: "",
        path: "products-pages/all-products",
        component: <AllProduct />,
      },
      {
        name: "How it Works?",
        type: "link",
        layout: "",
        path: "products-pages/how-it-works",
        component: <ProductCanvas />,
      },
      {
        name: "Certificates",
        type: "link",
        layout: "",
        path: "products-pages/certificates",
        component: <CertificateData />,
      },
    ],
  },
  {
    id: 43,
    name: "Product",
    type: "link",
    layout: "/forms",
    path: "products-pages/all-products/layout/:slug",
    icon: "",
    component: <ProductLayout />,
  },
  {
    id: 44,
    name: "Product Cards",
    type: "link",
    layout: "/forms",
    path: "products-pages/all-products/:slug/product-cards/:default_id",
    icon: "",
    component: <ProductCards />,
  },
  {
    id: 45,
    name: "Product Table",
    type: "link",
    layout: "/forms",
    path: "products-pages/all-products/:slug/tables/:default_id",
    icon: "",
    component: <ProductTables />,
  },
  {
    id: 46,
    name: "Product Table",
    type: "link",
    layout: "/forms",
    path: "products-pages/all-products/:slug/card-lists/:id",
    icon: "",
    component: <ProductCardLists />,
  },
  {
    id: 47,
    name: "Product Image",
    type: "link",
    layout: "/forms",
    path: "products-pages/all-products/:slug/image",
    icon: "",
    component: <ProductCardLists />,
  },
  {
    id: 48,
    name: "Product Text Box",
    type: "link",
    layout: "/forms",
    path: "products-pages/all-products/:slug/text-boxes/:default_id",
    icon: "",
    component: <ProductTextBoxes />,
  },
  {
    id: 49,
    name: "Product Chart",
    type: "link",
    layout: "/forms",
    path: "products-pages/all-products/:slug/charts/:default_id",
    icon: "",
    component: <Charts />,
  },
  {
    id: 50,
    name: "Product Cards",
    type: "link",
    layout: "/forms",
    path: "products-pages/how-it-works/product-cards/:default_id",
    icon: "",
    component: <ProductCards />,
  },
  {
    id: 51,
    name: "Product Table",
    type: "link",
    layout: "/forms",
    path: "products-pages/how-it-works/tables/:default_id",
    icon: "",
    component: <ProductTables />,
  },
  {
    id: 52,
    name: "Product Table",
    type: "link",
    layout: "/forms",
    path: "products-pages/how-it-works/card-lists/:default_id",
    icon: "",
    component: <ProductCardLists />,
  },
  {
    id: 53,
    name: "Product Image",
    type: "link",
    layout: "/forms",
    path: "products-pages/how-it-works/image",
    icon: "",
    component: <ProductCardLists />,
  },
  {
    id: 54,
    name: "Product Text Box",
    type: "link",
    layout: "/forms",
    path: "products-pages/how-it-works/text-boxes/:default_id",
    icon: "",
    component: <ProductTextBoxes />,
  },
  {
    id: 55,
    name: "Product Chart",
    type: "link",
    layout: "/forms",
    path: "products-pages/how-it-works/charts/:default_id",
    icon: "",
    component: <Charts />,
  },
  {
    id: 56,
    name: "Certificates",
    type: "link",
    layout: "/tables",
    path: "products-pages/certificates/all",
    icon: "",
    component: <CertificateData />,
  },
  // End Products Pages
  {
    id: 57,
    name: "News Pages",
    type: "accordion",
    layout: "",
    path: "/news-pages",
    icon: <MdNewspaper className="h-6 w-6" />,
    component: "",
    children: [
      {
        name: "Company News",
        type: "link",
        layout: "",
        path: "news-pages/company-news",
        component: <CompanyActivitiesData />,
      },
      {
        name: "CSR",
        type: "link",
        layout: "",
        path: "news-pages/csr",
        component: <CSRData />,
      },
      {
        name: "Corporate Governance",
        type: "link",
        layout: "",
        path: "news-pages/corporate-governance",
        component: <CorporateGovernanceData />,
      },
    ],
  },
  {
    id: 58,
    name: "Career",
    type: "link",
    layout: "",
    path: "career",
    icon: <MdWork className="h-6 w-6" />,
    component: <CareerData />,
  },
  {
    id: 59,
    name: "Footer",
    type: "link",
    layout: "",
    path: "footer",
    icon: <RiLayoutBottom2Fill className="h-6 w-6" />,
    component: <Footer />,
  },
  {
    id: 60,
    name: "Website Settings",
    type: "link",
    layout: "",
    path: "settings",
    icon: <MdSettings className="h-6 w-6" />,
    component: <Logo />,
  },
  // {
  //   id: 61,
  //   name: "Element Starter",
  //   type: "accordion",
  //   layout: "",
  //   path: "/element-starter",
  //   icon: <BiAtom className="h-6 w-6" />,
  //   component: "",
  //   children: [
  //     {
  //       name: "Text",
  //       type: "link",
  //       layout: "",
  //       path: "element-starter/text",
  //       component: <TextStarter />,
  //     },
  //     {
  //       name: "Short Description",
  //       type: "link",
  //       layout: "",
  //       path: "element-starter/short-description",
  //       component: <ShortDescriptionStarter />,
  //     },
  //   ],
  // },
];
export default routes;
