import axiosInstance from "./config";

export const createJumbotron = (body) => {
  return axiosInstance.post("/jumbotrons", body);
};

export const updateJumbotron = (id, body) => {
  return axiosInstance.post(`/jumbotrons/update/${id}`, body);
};

export const showJumbotron = (id) => {
  return axiosInstance.get(`/jumbotrons/${id}`);
};

export const deleteJumbotron = (id) => {
  return axiosInstance.get(`/jumbotrons/delete/${id}`);
};
