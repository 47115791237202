import React from "react";

// Libs
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { productElements } from "variables/elements";
import { useDisclosure } from "@chakra-ui/hooks";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { KeyboardSensor, useSensor, useSensors } from "@dnd-kit/core";

// APIs
import { elementActionHandler } from "utils/API";
import { showProductBySlug } from "api/ProductAPI";
import { getAllPageContent } from "api/PageAPI";
import { getPageContent } from "api/PageAPI";
import { uploadImages } from "api/ImageAPI";

// Components
import LockedLayout from "views/admin/page-builder/locked-layout";
import ElementsList from "views/admin/page-builder/components/ElementsList";
import MyPointerSensor from "views/admin/page-builder/components/PointerSensor";
import BasicModal from "components/modal/BasicModal";
import { HIWElements } from "variables/elements";
import Title from "views/admin/canvas/components/modals/Title";
import Text from "views/admin/canvas/components/modals/Text";
import Jumbotron from "views/admin/canvas/components/modals/Jumbotron";
import SingleImage from "views/admin/canvas/components/modals/SingleImage";

const ProductCanvas = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams();
  const elementTypes = slug ? productElements : HIWElements;
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = React.useState(false);
  const [productData, setProductData] = React.useState({});
  const [updateName, setUpdateName] = React.useState("");
  const [file, setFile] = React.useState(undefined);
  const [elements, setElements] = React.useState([]);
  const [oldElements, setOldElements] = React.useState([]);
  const [deletedElements, setDeletedElements] = React.useState([]);
  const [oldData, setOldData] = React.useState({});
  const [existingImage, setExistingImage] = React.useState(undefined);
  const [progress, setProgress] = React.useState(0);
  const [isUploading, setIsUploading] = React.useState(false);
  const sensors = useSensors(
    useSensor(MyPointerSensor, {
      onActivation: (event) => {
        return;
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const dropFile = (file) => {
    setFile(file);
  };

  const removeFile = () => {
    setFile(undefined);
  };

  const addElement = (el) => {
    const newEl = {
      ...el,
      id: "el-" + Math.floor(Math.random() * 100000),
      order: elements.length + 1,
      product_id: productData.id,
    };
    setElements([...elements, newEl]);
  };

  const arrangeElement = (el) => {
    // Change order element as index order
    const newElements = el.map((element, index) => {
      return {
        ...element,
        order: index + 1,
      };
    });
    setElements(newElements);
  };

  const removeElement = (el) => {
    const deletedEl = elements.find((element) => element.id === el);
    if (oldElements.find((element) => element.id === el))
      setDeletedElements([...deletedElements, deletedEl]);

    const newElements = elements.filter((element) => element.id !== el);
    setElements(newElements);
  };

  const updateElementBody = (_action, id) => {
    const targetEl = elements.find((el) => el.id === id);
    const targetType = targetEl.type;

    if (slug) {
      targetType === "product-card" &&
        navigate(
          `/products-pages/all-products/${slug}/product-cards/${targetEl?.default_id}`
        );
      targetType === "table" &&
        navigate(
          `/products-pages/all-products/${slug}/tables/${targetEl?.default_id}`
        );
      targetType === "card-list" &&
        navigate(
          `/products-pages/all-products/${slug}/card-lists/${targetEl?.default_id}`
        );
      targetType === "text-box" &&
        navigate(
          `/products-pages/all-products/${slug}/text-boxes/${targetEl?.default_id}`
        );
      targetType === "chart" &&
        navigate(
          `/products-pages/all-products/${slug}/charts/${targetEl?.default_id}`
        );
    } else {
      // navigate(`/products-pages/${productData.slug}/${targetPath}`);
      targetType === "product-card" &&
        navigate(`${location.pathname}/product-cards/${targetEl?.default_id}`);
      targetType === "table" &&
        navigate(`${location.pathname}/tables/${targetEl?.default_id}`);
      targetType === "card-list" &&
        navigate(`${location.pathname}/card-lists/${targetEl?.default_id}`);
      targetType === "text-box" &&
        navigate(`${location.pathname}/text-boxes/${targetEl?.default_id}`);
      targetType === "chart" &&
        navigate(`${location.pathname}/charts/${targetEl?.default_id}`);
    }
    targetType === "page-image" &&
      setExistingImage(elements.find((el) => el.id === id)?.body?.img);
    targetType === "jumbotron" &&
      setExistingImage(elements.find((el) => el.id === id)?.body?.img);

    setUpdateName(targetType);
    setOldData(targetEl);
    if (
      targetEl.body[`${targetType}_en`] ||
      targetEl.body[`${targetType}_id`]
    ) {
      reset({
        [`${targetType}_en`]: targetEl.body[`${targetType}_en`],
        [`${targetType}_id`]: targetEl.body[`${targetType}_id`],
      });
    } else {
      reset({
        subtitle_en: targetEl.body.subtitle_en,
        subtitle_id: targetEl.body.subtitle_id,
        title_en: targetEl.body.title_en,
        title_id: targetEl.body.title_id,
      });
    }
    onOpen();
  };

  const refactorElements = (el) => {
    const newEl = [];
    el.forEach((element) => {
      if (element.name === "text_boxes") element.name = "text-boxs";
      const newElement = {
        default_id: element.id, // default_id is used to identify the element in the database
        id: "el-" + Math.floor(Math.random() * 100000),
        icon: elementTypes.find(
          (el) => el.type === element.name.replace(/_/g, "-").slice(0, -1)
        )?.icon,
        name: elementTypes.find(
          (el) => el.type === element.name.replace(/_/g, "-").slice(0, -1)
        )?.name,
        order: element.order,
        product_id: element.product_id,
        type: element.name.replace(/_/g, "-").slice(0, -1),
        body: {
          ...element.body,
        },
      };
      newEl.push(newElement);
    });
    setElements(newEl);
    setOldElements(newEl);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const promises = [];
    let finalBody = null;
    const isIdentical =
      JSON.stringify(oldElements) === JSON.stringify(elements);

    if (slug)
      finalBody = {
        product_id: productData.id,
      };
    else
      finalBody = {
        page_id: productData.id,
      };

    if (!isIdentical && oldElements.length === 0) {
      elements.forEach((el, index) => {
        el.type !== "divider" &&
          (finalBody = {
            ...finalBody,
            order: index + 1,
            body: {
              ...el.body,
            },
          });
        el.type === "divider" &&
          (finalBody = {
            ...finalBody,
            order: index + 1,
          });

        finalBody.body = JSON.stringify(finalBody.body);
        promises.push(elementActionHandler(finalBody, el.type, "create"));
      });

      toast.promise(Promise.all(promises), {
        pending: "Loading...",
        success: "Element berhasil dibuat!",
        error: "Element gagal dibuat!",
      });

      Promise.all(promises).then(() => {
        getProduct();
        setIsLoading(false);
      });
    }
    if (!isIdentical && oldElements.length > 0) {
      const promisesDelete = [];
      const promisesUpdate = [];
      const newElements = [];

      elements.filter((el) => {
        newElements.push(
          !oldElements.some((f) => {
            return f.id === el.id;
          }) === true && el
        );
      });
      newElements.filter(Boolean);

      // Remove deleted elements
      deletedElements.length > 0 &&
        deletedElements.forEach(async (el) => {
          promisesDelete.push(elementActionHandler(el, el.type, "delete"));
        });

      if (newElements.length === 0) {
        toast.promise(Promise.all(promisesDelete), {
          pending: "Loading...",
          success: "Element berhasil dihapus!",
          error: "Element gagal dihapus!",
        });

        Promise.all(promisesDelete).then(() => {
          getProduct();
          setIsLoading(false);
        });
      }

      if (newElements.length > 0) {
        Promise.all(promisesDelete).then(() => {
          // Get only modified elements
          const modifiedEl = elements.filter(
            (el) => !deletedElements.includes(el)
          );

          modifiedEl.forEach(async (el) => {
            el.type !== "divider" &&
              (finalBody = {
                ...finalBody,
                type: el.type,
                section: el.section_id,
                id: el.default_id ? el.default_id : el.id,
                order: el.order,
                body: {
                  ...el.body,
                },
              });
            el.type === "divider" &&
              (finalBody = {
                ...finalBody,
                type: el.type,
                section: el.section_id,
                id: el.default_id ? el.default_id : el.id,
                order: el.order,
              });

            finalBody.body = JSON.stringify(finalBody.body);
            promisesUpdate.push(
              el?.default_id
                ? elementActionHandler(finalBody, el.type, "update")
                : elementActionHandler(finalBody, el.type, "create")
            );
          });

          toast.promise(Promise.all(promisesUpdate), {
            pending: "Loading...",
            success: "Element berhasil diupdate!",
            error: "Element gagal diupdate!",
          });

          Promise.all(promisesUpdate).then(() => {
            getProduct();
            setIsLoading(false);
          });
        });
      }
    }

    setExistingImage(undefined);
  };

  const updateBodyElement = async (data) => {
    Object.entries(data).forEach(([key, value]) => {
      if (!value) delete data[key];
    });

    let finalBody = {
      id: oldData.default_id,
      order: oldData.order,
      body: {
        ...data,
      },
    };

    if (file) {
      setIsUploading(true);

      const imgBody = {
        images: [file],
      };

      try {
        const res = await uploadImages(imgBody, setProgress);
        const img = res.data.data.data[0];

        finalBody.body = {
          ...finalBody.body,
          img: img,
        };

        setFile(undefined);
        setIsUploading(false);
        onClose();
      } catch (error) {
        console.error(error);
        toast.error("Gambar gagal diupload!");
        setIsUploading(false);
        onClose();
      }
    } else if (existingImage)
      finalBody.body = {
        ...finalBody.body,
        img: existingImage,
      };

    finalBody.body = JSON.stringify(finalBody.body);
    const promise = elementActionHandler(finalBody, updateName, "update");

    toast.promise(promise, {
      pending: "Loading...",
      success: "Data berhasil diperbarui!",
      error: "Data gagal diperbarui!",
    });

    promise
      .then(() => {
        getProduct();
        reset({});
        setExistingImage(undefined);
        onClose();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getProduct = async () => {
    if (slug)
      showProductBySlug(slug)
        .then((res) => {
          setProductData(res.data.data);
          refactorElements(res.data.data.contents);
        })
        .catch((err) => {
          console.error(err);
        });
    else {
      const pathname = location.pathname
        .split("/")[2]
        .toLowerCase()
        .replace(/ /g, "-");
      const allPageData = await getAllPageContent();
      const targetPage = allPageData.data.data.find(
        (el) => el.name.toLowerCase().replace(/ /g, "-") === pathname
      );
      const slug = targetPage.name.toLowerCase().replace(/ /g, "-");

      getPageContent(targetPage.id)
        .then((res) => {
          setProductData({ ...res.data.data, slug: slug });
          refactorElements(res.data.data.contents);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  React.useEffect(() => {
    getProduct();
  }, []);

  return (
    <>
      <ElementsList elements={elementTypes} handleClick={addElement} />
      <LockedLayout
        title={productData?.name ?? "Product Page Builder"}
        description={
          "Drag & drop element di bawah untuk mengatur urutan element."
        }
        items={elements}
        sensors={sensors}
        arrangeItems={arrangeElement}
        rightCTA={true}
        CTACopy="Simpan Layout"
        isCTALoading={isLoading}
        onClick={handleSubmit(onSubmit)}
        disabled={
          JSON.stringify(oldElements) === JSON.stringify(elements) || isLoading
        }
        updateItemUrl={
          JSON.stringify(oldElements) === JSON.stringify(elements)
            ? updateElementBody
            : undefined
        }
        handleRemoveItem={removeElement}
      />
      <BasicModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setExistingImage(undefined);
          setFile(undefined);
          reset();
        }}
        header="Update Data"
        size="4xl"
        scrollBehavior="inside"
      >
        {isUploading ? (
          progress !== 100 ? (
            <>
              <div className="text-center">
                <p className="mb-4 text-lg font-semibold text-navy-700">
                  Mengupload gambar ...
                </p>
                <h3 className="mb-3 text-3xl font-bold text-gray-700">
                  {progress}%
                </h3>
              </div>
              <div className="relative h-4 w-full overflow-hidden rounded-2xl bg-gray-300">
                <div
                  className="absolute top-0 left-0 h-full bg-brand-500 
                      transition-all duration-500 ease-linear"
                  style={{
                    width: `${progress}%`,
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <p className="text-center text-lg font-semibold text-navy-700">
                Menyimpan data ...
              </p>
            </>
          )
        ) : (
          <form
            encType="multipart/form-data"
            onSubmit={handleSubmit(updateBodyElement)}
          >
            {updateName === "page-image" && (
              <SingleImage
                errors={errors}
                register={register}
                dropFile={dropFile}
                removeFile={removeFile}
                existingImage={existingImage}
                file={file}
              />
            )}
            {(updateName === "text" || updateName === "description") && (
              <Text
                updateName={updateName}
                errors={errors}
                register={register}
              />
            )}
            {(updateName === "title" || updateName === "subtitle") && (
              <Title
                updateName={updateName}
                errors={errors}
                register={register}
              />
            )}
            {updateName === "jumbotron" && (
              <Jumbotron
                errors={errors}
                register={register}
                dropFile={dropFile}
                removeFile={removeFile}
                existingImage={existingImage}
                file={file}
              />
            )}
            {/* Button Submit & Close */}
            <div className="float-right mt-8 flex gap-3">
              <button
                className="linear rounded-[20px] bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                type="submit"
              >
                Update
              </button>
              <button
                className="linear rounded-[20px] bg-white px-4 py-2 text-base font-semibold text-gray-600 transition duration-200 dark:bg-transparent dark:text-white dark:opacity-90 dark:active:opacity-90"
                onClick={() => {
                  reset();
                  setExistingImage(undefined);
                  setFile(undefined);
                  onClose();
                }}
                type="button"
              >
                Batal
              </button>
            </div>
          </form>
        )}
      </BasicModal>
    </>
  );
};

export default ProductCanvas;
