import axiosInstance from "./config";

export const showAllInternalAudit = () => {
  return axiosInstance.get("/audit-secretary");
};

export const showInternalAudit = (id) => {
  return axiosInstance.get(`/audit-secretary/${id}`);
};

export const createInternalAudit = (body) => {
  return axiosInstance.post("/audit-secretary", body);
};

export const deleteInternalAudit = (id) => {
  return axiosInstance.get(`/audit-secretary/delete/${id}`);
};

export const updateInternalAudit = (id, body) => {
  return axiosInstance.post(`/audit-secretary/update/${id}`, body);
};
