import React from "react";
import { Trash } from "react-feather";

export const TableActionButton = (props) => {
  const { row, updateData, deleteData } = props;

  return (
    <div className="flex items-center gap-3">
      <button
        className={`flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full border border-brand-500 bg-transparent py-2 px-4 text-sm font-medium text-brand-500 transition duration-200 hover:bg-brand-600 hover:text-white active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
        // onClick={() => updateData("updating", row.cell.row.original)}
        onClick={() => updateData("updating", row)}
      >
        Update
      </button>
      <div
        className={`relative flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full bg-red-100 py-2 px-3 text-sm font-medium text-red-600 transition duration-200 hover:text-red-700 dark:bg-red-500 dark:text-red-400 dark:hover:text-red-300 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
      >
        <button
          className="absolute top-0 left-0 h-full w-full sm-max:w-full"
          // onClick={() => deleteData(row.cell.row.original.id)}
          onClick={() => deleteData(row)}
        />
        <Trash width={16} />
      </div>
    </div>
  );
};
