import { useDisclosure } from "@chakra-ui/hooks";
import { showFooter, updateFooter } from "api/FooterAPI";
import { uploadImages } from "api/ImageAPI";
import CardContainer from "components/card/CardContainer";
import DropzoneFile from "components/dropzone/DropzoneFile";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import BasicModal from "components/modal/BasicModal";
import Widget from "components/widget/Widget";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MdPhotoLibrary, MdShortText } from "react-icons/md";
import { toast } from "react-toastify";

const Footer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [content, setContent] = useState({
    loading: true,
    data: null,
  });
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [updateName, setUpdateName] = useState("");
  const [tmpOldImg, setTmpOldImg] = useState([]);
  const [payload, setPayload] = useState({
    body: {
      file: undefined,
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // File upload
  const dropFile = (file) => {
    setPayload((prev) => ({
      ...prev,
      body: {
        ...prev.body,
        file: file,
      },
    }));
    setTmpOldImg([...tmpOldImg, content.data.logos[0]]);
  };

  const removeFile = () => {
    setPayload({
      ...payload,
      body: {
        ...payload.body,
        file: undefined,
      },
    });
    setTmpOldImg((prev) => prev.filter((item, index) => index !== 0));
  };
  // End file upload

  const showUpdateModal = (name) => {
    setUpdateName(name);
    onOpen();
  };

  const onSubmit = async (data) => {
    let promise = null;
    let finalBody = {
      ...content.data,
    };

    if (updateName === "images") {
      let existingImageId = content.data.logos; // this is pass by reference
      let imageBody = null;

      imageBody = [payload.body.file].filter(Boolean);

      const finalImageBody = {
        images: imageBody,
      };

      if (imageBody) {
        setIsUploading(true);
        await uploadImages(finalImageBody, setProgress)
          .then((res) => {
            setIsUploading(false);
            const images = res.data.data.data;
            finalBody = {
              ...finalBody,
              logos: images,
            };

            tmpOldImg.forEach((item, index) => {
              const oldIndex = content.data.logos.findIndex(
                (id) => id === item
              );
              if (~oldIndex) existingImageId[oldIndex] = images[index];
            });

            setPayload({
              body: {
                file: undefined,
              },
            });
          })
          .catch((err) => {
            setIsUploading(false);
            console.error(err);
            toast.error("Gagal mengunggah foto, silahkan coba lagi!");
          });
      }
    } else {
      finalBody = {
        ...finalBody,
        ...data,
      };
      delete finalBody.created_at;
      delete finalBody.updated_at;
      delete finalBody.id;
    }

    finalBody.logos = JSON.stringify(finalBody.logos);
    promise = updateFooter(finalBody);

    toast.promise(promise, {
      pending: "Loading...",
      success: "Berhasil di-update!",
      error: "Gagal di-update!",
    });

    promise
      .then((res) => {
        if (res.status === 200) {
          getContent();
          onClose();
          reset({});
        }
      })
      .catch((err) => {
        console.error(err);
      });

    reset({});
    onClose();
  };

  const getContent = () => {
    showFooter()
      .then((res) => {
        setContent({
          loading: false,
          data: res.data.data,
        });
      })
      .catch((err) => {
        console.error(err);
        toast.error("Gagal memuat data Footer! Lakukan refresh halaman");
      });
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
    <>
      <CardContainer
        title="Footer"
        description="Kelola konten footer pada semua halaman"
      >
        <div className="flex flex-col gap-3">
          {content?.data &&
            Object.entries(content?.data)?.map(([key, value], index) => {
              if (key === "id" || key === "created_at" || key === "updated_at")
                return null;
              if (key === "logos")
                return (
                  <div key={index}>
                    <Widget
                      isLoading={content.loading}
                      title={key.replace(/_/g, " ").toUpperCase()}
                      subtitle={value.length + " Gambar"}
                      icon={<MdPhotoLibrary />}
                      updateUrl={() => showUpdateModal("images")}
                    />
                  </div>
                );
              else
                return (
                  <div key={index}>
                    <Widget
                      isLoading={content.loading}
                      title={key.replace(/_/g, " ").toUpperCase()}
                      subtitle={value}
                      icon={<MdShortText />}
                      updateUrl={() => showUpdateModal(key)}
                    />
                  </div>
                );
            })}
        </div>
      </CardContainer>
      <BasicModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          reset({});
        }}
        header="Update Data"
        scrollBehavior="inside"
      >
        {isUploading ? (
          progress !== 100 ? (
            <>
              <div className="text-center">
                <p className="mb-4 text-lg font-semibold text-navy-700">
                  Mengupload gambar ...
                </p>
                <h3 className="mb-3 text-3xl font-bold text-gray-700">
                  {progress}%
                </h3>
              </div>
              <div className="relative h-4 w-full overflow-hidden rounded-2xl bg-gray-300">
                <div
                  className="absolute top-0 left-0 h-full bg-brand-500 
                  transition-all duration-500 ease-linear"
                  style={{
                    width: `${progress}%`,
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <p className="text-center text-lg font-semibold text-navy-700">
                Menyimpan data ...
              </p>
            </>
          )
        ) : (
          <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
            {updateName === "images" ? (
              <div className="flex flex-col gap-5">
                {content.data?.logos?.length > 0 && (
                  <div className="flex w-full items-center gap-3">
                    <div className="flex h-40 w-full justify-center overflow-hidden rounded-xl border-2 border-gray-200 p-1 text-center">
                      <img
                        src={
                          process.env.REACT_APP_API_ENDPOINT +
                          "/images/" +
                          content?.data?.logos[0]
                        }
                        className="h-full"
                        alt="Logo Footer"
                      />
                    </div>
                  </div>
                )}
                <div className="flex w-full items-center gap-3">
                  <DropzoneFile
                    text="Upload file"
                    accept={{
                      "image/jpeg": [".jpg", ".jpeg", ".png"],
                    }}
                    dropFile={dropFile}
                    removeFile={removeFile}
                    file={payload.body.file}
                  />
                </div>
              </div>
            ) : updateName === "policy_id" || updateName === "policy_en" ? (
              <TextField
                extra="mb-3"
                label={`${
                  updateName.replace(/_/g, " ").charAt(0).toUpperCase() +
                  updateName.replace(/_/g, " ").slice(1)
                }*`}
                id={updateName}
                type="text"
                name={updateName}
                errors={errors}
                register={register}
                rows={10}
                defaultValue={content?.data && content?.data[updateName]}
                validationSchema={{
                  required: `${
                    updateName.replace(/_/g, " ").charAt(0).toUpperCase() +
                    updateName.replace(/_/g, " ").slice(1)
                  } harus diisi!`,
                }}
              />
            ) : (
              <>
                <InputField
                  extra="mb-3"
                  label={`${
                    updateName.replace(/_/g, " ").charAt(0).toUpperCase() +
                    updateName.replace(/_/g, " ").slice(1)
                  }*`}
                  id={updateName}
                  type="text"
                  name={updateName}
                  errors={errors}
                  register={register}
                  defaultValue={content?.data && content?.data[updateName]}
                  validationSchema={{
                    required: `${
                      updateName.replace(/_/g, " ").charAt(0).toUpperCase() +
                      updateName.replace(/_/g, " ").slice(1)
                    } harus diisi!`,
                  }}
                />
              </>
            )}
            {/* Button Submit & Close */}
            <div className="float-right mt-8 flex gap-3">
              <button
                className="linear rounded-[20px] bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                type="submit"
              >
                Update
              </button>
              <button
                className="linear rounded-[20px] bg-white px-4 py-2 text-base font-semibold text-gray-600 transition duration-200 dark:bg-transparent dark:text-white dark:opacity-90 dark:active:opacity-90"
                onClick={() => {
                  onClose();
                  reset({});
                }}
                type="button"
              >
                Batal
              </button>
            </div>
          </form>
        )}
      </BasicModal>
    </>
  );
};

export default Footer;
