import axiosInstance from "./config";

export const createPageImage = (body) => {
  return axiosInstance.post("/page-images", body);
};

export const updatePageImage = (id, body) => {
  return axiosInstance.post(`/page-images/update/${id}`, body);
};

export const deletePageImage = (id) => {
  return axiosInstance.get(`/page-images/delete/${id}`);
};
