import React from "react";
import InputField from "components/fields/InputField";

const Link = (props) => {
  const { updateName, errors, register } = props;

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-3">
        <InputField
          extra="mb-3"
          label={`Label (EN)*`}
          id={`en`}
          type="text"
          name={`en`}
          errors={errors}
          register={register}
          defaultValue={""}
          validationSchema={{
            required: `Label (EN) harus diisi!`,
          }}
        />
        <InputField
          extra="mb-3"
          label={`Label (ID)*`}
          id={`id`}
          type="text"
          name={`id`}
          errors={errors}
          register={register}
          defaultValue={""}
          validationSchema={{
            required: `Label (ID) harus diisi!`,
          }}
        />
      </div>
      <InputField
        extra="mb-3"
        label={`Link*`}
        id={`${updateName}`}
        type="text"
        name={`${updateName}`}
        errors={errors}
        register={register}
        defaultValue={""}
        validationSchema={{
          required: `${
            updateName.charAt(0).toUpperCase() + updateName.slice(1)
          } harus diisi!`,
        }}
      />
    </div>
  );
};

export default Link;
