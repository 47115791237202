import axios from "axios";
import axiosInstance from "./config";

export const createBasicInput = (data) => {
  return axiosInstance.get("/todos");
};

export const getVideoData = (id) => {
  return axios.get(
    `https://www.googleapis.com/youtube/v3/videos?id=${id}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}&part=snippet,contentDetails`
  );
};
