import axiosInstance from "./config";

export const showAllProduct = () => {
  return axiosInstance.get("/products");
};

export const showProduct = (id) => {
  return axiosInstance.get(`/products/${id}`);
};

export const showProductBySlug = (slug) => {
  return axiosInstance.get(`/products/slug/${slug}`);
};

export const createProduct = (body) => {
  return axiosInstance.post("/products", body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteProduct = (id) => {
  return axiosInstance.get(`/products/delete/${id}`);
};

export const updateProduct = (id, body) => {
  return axiosInstance.post(`/products/update/${id}`, body);
};
