import axiosInstance from "./config";

export const createSubtitle = (body) => {
  return axiosInstance.post("/subtitles", body);
};

export const updateSubtitle = (id, body) => {
  return axiosInstance.post(`/subtitles/update/${id}`, body);
};

export const deleteSubtitle = (id) => {
  return axiosInstance.get(`/subtitles/delete/${id}`);
};
