import axiosInstance from "./config";

export const createLogo = (body) => {
  return axiosInstance.post("/logos", body);
};

export const updateLogo = (id, body) => {
  return axiosInstance.post(`/logos/update/${id}`, body);
};

export const getAllLogo = () => {
  return axiosInstance.get(`/logos`);
};

export const showLogo = (id) => {
  return axiosInstance.get(`/logos/${id}`);
};

export const deleteLogo = (id) => {
  return axiosInstance.get(`/logos/delete/${id}`);
};
