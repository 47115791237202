import React, { useEffect, useState } from "react";

// APIs
import { uploadImages } from "api/ImageAPI";
import { getHomeContent } from "api/PageAPI";
import { updateHomeSection } from "api/HomeAPI";

// Libs
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/hooks";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";

// Views & Components
import FormLayout from "views/admin/forms";
import DropzoneFile from "components/dropzone/DropzoneFile";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import BasicModal from "components/modal/BasicModal";

const HomeCarouselForm = () => {
  const section = "carousel";
  const navigate = useNavigate();
  const { sectionId, itemId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isUpdate = itemId ? true : false;
  const [existingImg, setExistingImg] = useState(null);
  const [progress, setProgress] = useState(0);
  const [payload, setPayload] = useState({
    page_id: 1, // Home Page's ID
    order: null,
    body: {
      img: 0,
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // File upload
  const dropFile = (file) => {
    setPayload((prev) => ({
      ...prev,
      file: file,
    }));
  };

  const removeFile = () => {
    setPayload({
      ...payload,
      file: undefined,
    });
  };
  // End file upload

  const onSubmit = async (data) => {
    let promise = null;
    let finalBody = null;
    let newBody = null;

    if (!isUpdate && payload.file === undefined)
      return toast.error("File is required");
    else {
      if (isUpdate) {
        newBody = _.cloneDeep(payload.body); // Deep clone the body using lodash
        newBody.title_id[itemId] = data.title_id;
        newBody.title_en[itemId] = data.title_en;
        newBody.subtitle_id[itemId] = data.subtitle_id;
        newBody.subtitle_en[itemId] = data.subtitle_en;
      }

      if (payload.file) {
        onOpen();
        const body = {
          images: [payload.file],
        };
        await uploadImages(body, setProgress)
          .then((res) => {
            delete payload.file;

            // If body is null, it means that the section is empty
            if (payload.body === null)
              finalBody = {
                ...payload,
                body: {
                  title_id: [data.title_id],
                  title_en: [data.title_en],
                  subtitle_id: [data.subtitle_id],
                  subtitle_en: [data.subtitle_en],
                  img: res.data.data.data,
                },
              };
            // If body is not null, it means that the section is not empty and we need to push the new data
            else {
              if (!isUpdate)
                finalBody = {
                  ...payload,
                  body: {
                    title_id: [...payload.body.title_id, data.title_id],
                    title_en: [...payload.body.title_en, data.title_en],
                    subtitle_id: [
                      ...payload.body.subtitle_id,
                      data.subtitle_id,
                    ],
                    subtitle_en: [
                      ...payload.body.subtitle_en,
                      data.subtitle_en,
                    ],
                    img: [...payload.body.img, ...res.data.data.data],
                  },
                };
              else {
                newBody.img[itemId] = res.data.data.data; // replace the old image with the new one
                finalBody = {
                  ...payload,
                  body: {
                    ...newBody,
                  },
                };
              }
            }
          })
          .catch((err) => {
            console.error(err);
            onClose();
            toast.error("Gagal mengunggah file!");
          });
      } else {
        // If there is no file attached and on updating mode
        if (isUpdate) {
          finalBody = {
            ...payload,
            body: newBody,
          };
        }
      }

      finalBody.body = JSON.stringify(finalBody.body); // convert body to string
      promise = updateHomeSection(sectionId, finalBody, setProgress);

      toast.promise(promise, {
        pending: "Loading...",
        success:
          section.charAt(0).toUpperCase() +
          section.slice(1) +
          " berhasil di" +
          (isUpdate ? "-update" : "tambahkan") +
          "!",
        error: "Gagal menambahkan " + section + "!",
      });

      promise
        .then((res) => {
          navigate(`/home-page/${section}`, { replace: true });
          if (res.status === 200 || res.status === 201) {
            reset();
          }
        })
        .catch((err) => {
          console.error(err);
          onClose();
        });
    }
  };

  const getContent = () => {
    getHomeContent().then((res) => {
      const index = res.data.data.findIndex((item) => item.name === section);
      if (~index) {
        setPayload({
          file: undefined,
          order: res.data.data[index].order,
          ...res.data.data[index],
        });
        if (isUpdate) {
          setExistingImg(res.data.data[index].body.img[itemId]);
          reset({
            title_id: res.data.data[index].body.title_id[itemId],
            title_en: res.data.data[index].body.title_en[itemId],
            subtitle_id: res.data.data[index].body.subtitle_id[itemId],
            subtitle_en: res.data.data[index].body.subtitle_en[itemId],
          });
        }
      }
    });
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
    <>
      <FormLayout
        title="Home Carousel"
        description="Form untuk mengatur home carousel"
        children={
          <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex w-full flex-col gap-3 rounded-2xl bg-white p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
              <div className="flex items-start gap-3 sm-max:flex-col-reverse">
                <div className="w-full flex-1">
                  <InputField
                    extra="mb-3"
                    label="Title (EN)*"
                    placeholder="Judul Pendek Bahasa Inggris"
                    id="title_en"
                    type="text"
                    name="title_en"
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: "Title (EN) harus diisi",
                    }}
                  />
                  <TextField
                    extra="mb-3"
                    label="Sub Title (EN)*"
                    placeholder="Sub Judul Bahasa Inggris"
                    id="subtitle_en"
                    cols="20"
                    rows="5"
                    name="subtitle_en"
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: "Sub Title (EN) harus diisi",
                    }}
                  />
                  <div className="my-10 w-full border border-dashed border-gray-300" />
                  <InputField
                    extra="mb-3"
                    label="Title (ID)*"
                    placeholder="Judul Pendek Bahasa Indonesia"
                    id="title_id"
                    type="text"
                    name="title_id"
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: "Title (ID) harus diisi",
                    }}
                  />
                  <TextField
                    extra="mb-3"
                    label="Sub Title (ID)*"
                    placeholder="Sub Judul Bahasa Indonesia"
                    id="subtitle_id"
                    cols="20"
                    rows="5"
                    name="subtitle_id"
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: "Sub Title (ID) harus diisi",
                    }}
                  />
                </div>
                <div className="flex flex-1 flex-col">
                  {isUpdate && (
                    <div className="m-3 flex flex-col items-center justify-center gap-2">
                      <p className="w-full text-center text-sm font-medium text-navy-700">
                        Gambar Sebelumnya
                      </p>
                      {existingImg === null ? (
                        <Skeleton height={100} width={120} />
                      ) : (
                        <div className="relative my-3 flex w-full max-w-xs items-center justify-center rounded-xl border-2 border-gray-200">
                          <img
                            src={
                              existingImg === null
                                ? "https://via.placeholder.com/120x100?text=No+Image"
                                : process.env.REACT_APP_API_ENDPOINT +
                                  "/images/" +
                                  existingImg
                            }
                            alt={
                              existingImg !== null &&
                              process.env.REACT_APP_API_ENDPOINT +
                                "/images/" +
                                existingImg
                            }
                            className="rounded-lg"
                          />
                        </div>
                      )}
                    </div>
                  )}
                  <DropzoneFile
                    text="Upload file"
                    accept={{
                      "image/jpeg": [".jpg", ".jpeg", ".png"],
                    }}
                    dropFile={dropFile}
                    removeFile={removeFile}
                    file={payload.file}
                    // doUpload={true}
                    maxSize={2048000}
                    // callbackUploadFile={callbackUploadFile}
                  />
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  className="linear max-w-max rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                >
                  Save Data
                </button>
              </div>
            </div>
          </form>
        }
      />
      <BasicModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        hasCloseBtn={false}
        header={""}
        size="sm"
      >
        {progress !== 100 ? (
          <>
            <div className="text-center">
              <p className="mb-4 text-lg font-semibold text-navy-700">
                Mengupload gambar ...
              </p>
              <h3 className="mb-3 text-3xl font-bold text-gray-700">
                {progress}%
              </h3>
            </div>
            <div className="relative h-4 w-full overflow-hidden rounded-2xl bg-gray-300">
              <div
                className="absolute top-0 left-0 h-full bg-brand-500 
              transition-all duration-500 ease-linear"
                style={{
                  width: `${progress}%`,
                }}
              />
            </div>
          </>
        ) : (
          <>
            <p className="text-center text-lg font-semibold text-navy-700">
              Menyimpan data ...
            </p>
          </>
        )}
      </BasicModal>
    </>
  );
};

export default HomeCarouselForm;
