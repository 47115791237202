import { convertDuration } from "utils";
import { getVideoData } from "api/VideoAPI";
import { updateText, createText, deleteText } from "api/TextAPI";
import { deleteLink, updateLink, createLink } from "api/LinkAPI";
import { deleteList, updateList, createList } from "api/ListAPI";
import { updateTable, createTable, deleteTable } from "api/TableAPI";
import { updateTitle, createTitle, deleteTitle } from "api/TitleAPI";
import { createChart, updateChart, deleteChart } from "api/ChartAPI";
import { deleteDivider, createDivider, updateDivider } from "api/DividerAPI";
import { createTextBox, updateTextBox, deleteTextBox } from "api/TextBoxAPI";
import { createSection, updateSection, deleteSection } from "api/SectionAPI";
import {
  updateCardList,
  deleteCardList,
  createCardList,
} from "api/CardListAPI";
import {
  updateImageList,
  deleteImageList,
  createImageList,
} from "api/ImageListAPI";
import {
  updateProductCards,
  createProductCards,
  deleteProductCards,
} from "api/ProductCardAPI";
import {
  updateSubtitle,
  createSubtitle,
  deleteSubtitle,
} from "api/SubtitleAPI";
import {
  createMemberCard,
  updateMemberCard,
  deleteMemberCard,
} from "api/MemberCardAPI";
import {
  createMapChart,
  updateMapChart,
  deleteMapChart,
} from "api/MapChartAPI";
import {
  createPageImage,
  updatePageImage,
  deletePageImage,
} from "api/PageImage";
import {
  createJumbotron,
  updateJumbotron,
  deleteJumbotron,
} from "api/JumbotronAPI";
import {
  createTemplate,
  updateTemplate,
  deleteTemplate,
} from "api/TemplateAPI";

export const getVideoPreviewData = async (youtubeId) => {
  return await getVideoData(youtubeId).then((res) => {
    const title = res?.data?.items[0]?.snippet?.title;
    const subtitle = res?.data?.items[0]?.snippet?.description;
    const duration = res?.data?.items[0]?.contentDetails?.duration.replace(
      "PT",
      ""
    );
    const thumbnailHigh = res?.data?.items[0]?.snippet?.thumbnails?.high?.url;
    const runtime = convertDuration(duration);
    const youtube_video_id = youtubeId;

    return {
      title,
      subtitle,
      runtime,
      thumbnailHigh,
      youtube_video_id,
    };
  });
};

const isTemplate = (type) => {
  if (type.includes("template")) return type;
};

export const elementActionHandler = (finalBody, type, action) => {
  switch (type) {
    case "title":
      return action === "create"
        ? createTitle(finalBody)
        : action === "update"
        ? updateTitle(finalBody.id, finalBody)
        : deleteTitle(finalBody.default_id);
    case "subtitle":
      return action === "create"
        ? createSubtitle(finalBody)
        : action === "update"
        ? updateSubtitle(finalBody.id, finalBody)
        : deleteSubtitle(finalBody.default_id);
    case "text":
      return action === "create"
        ? createText(finalBody)
        : action === "update"
        ? updateText(finalBody.id, finalBody)
        : deleteText(finalBody.default_id);
    case "product-card":
      return action === "create"
        ? createProductCards(finalBody)
        : action === "update"
        ? updateProductCards(finalBody.id, finalBody)
        : deleteProductCards(finalBody.default_id);
    case "table":
      return action === "create"
        ? createTable(finalBody)
        : action === "update"
        ? updateTable(finalBody.id, finalBody)
        : deleteTable(finalBody.default_id);
    case "card-list":
      return action === "create"
        ? createCardList(finalBody)
        : action === "update"
        ? updateCardList(finalBody.id, finalBody)
        : deleteCardList(finalBody.default_id);
    case "link":
      return action === "create"
        ? createLink(finalBody)
        : action === "update"
        ? updateLink(finalBody.id, finalBody)
        : deleteLink(finalBody.default_id);
    case "list":
      return action === "create"
        ? createList(finalBody)
        : action === "update"
        ? updateList(finalBody.id, finalBody)
        : deleteList(finalBody.default_id);
    case "image-list":
      return action === "create"
        ? createImageList(finalBody)
        : action === "update"
        ? updateImageList(finalBody.id, finalBody)
        : deleteImageList(finalBody.default_id);
    case "member-card":
      return action === "create"
        ? createMemberCard(finalBody)
        : action === "update"
        ? updateMemberCard(finalBody.id, finalBody)
        : deleteMemberCard(finalBody.default_id);
    case "chart":
      return action === "create"
        ? createChart(finalBody)
        : action === "update"
        ? updateChart(finalBody.id, finalBody)
        : deleteChart(finalBody.default_id);
    case "map-chart":
      return action === "create"
        ? createMapChart(finalBody)
        : action === "update"
        ? updateMapChart(finalBody.id, finalBody)
        : deleteMapChart(finalBody.default_id);
    case "text-box":
      return action === "create"
        ? createTextBox(finalBody)
        : action === "update"
        ? updateTextBox(finalBody.id, finalBody)
        : deleteTextBox(finalBody.default_id);
    case "jumbotron":
      return action === "create"
        ? createJumbotron(finalBody)
        : action === "update"
        ? updateJumbotron(finalBody.id, finalBody)
        : deleteJumbotron(finalBody.default_id);
    case "page-image":
      return action === "create"
        ? createPageImage(finalBody)
        : action === "update"
        ? updatePageImage(finalBody.id, finalBody)
        : deletePageImage(finalBody.default_id);
    case "section":
      return action === "create"
        ? createSection(finalBody)
        : action === "update"
        ? updateSection(finalBody.id, finalBody)
        : deleteSection(finalBody.default_id);
    case isTemplate(type):
      return action === "create"
        ? createTemplate(finalBody)
        : action === "update"
        ? updateTemplate(finalBody.id, finalBody)
        : deleteTemplate(finalBody.default_id);
    // case "template-2":
    //   return action === "create"
    //     ? createTemplate(finalBody)
    //     : action === "update"
    //     ? updateTemplate(finalBody.id, finalBody)
    //     : deleteTemplate(finalBody.default_id);
    // case "template-3":
    //   return action === "create"
    //     ? createTemplate(finalBody)
    //     : action === "update"
    //     ? updateTemplate(finalBody.id, finalBody)
    //     : deleteTemplate(finalBody.default_id);
    case "divider":
      return action === "create"
        ? createDivider(finalBody)
        : action === "update"
        ? updateDivider(finalBody.id, finalBody)
        : deleteDivider(finalBody.default_id);
    default:
      return;
  }
};
