import axiosInstance from "./config";

export const showAllCSR = () => {
  return axiosInstance.get("/csr");
};

export const showCSR = (id) => {
  return axiosInstance.get(`/csr/${id}`);
};

export const createCSR = (body) => {
  return axiosInstance.post("/csr", body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteCSR = (id) => {
  return axiosInstance.get(`/csr/delete/${id}`);
};

export const updateCSR = (id, body) => {
  return axiosInstance.post(`/csr/update/${id}`, body);
};
