import axiosInstance from "./config";

export const uploadImages = (body, updateProgress) => {
  return axiosInstance.post("/images", body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percent = Math.floor((loaded * 100) / total);
      updateProgress(percent);
    },
  });
};
