import React, { useEffect, useState } from "react";
import CardContainer from "components/card/CardContainer";
import Widget from "components/widget/Widget";
import {
  MdDescription,
  MdNumbers,
  MdPhotoLibrary,
  MdShortText,
  MdTextFields,
} from "react-icons/md";
import BasicModal from "components/modal/BasicModal";
import { useDisclosure } from "@chakra-ui/hooks";
import InputField from "components/fields/InputField";
import { useForm } from "react-hook-form";
import DropzoneFile from "components/dropzone/DropzoneFile";
import TextField from "components/fields/TextField";
import { toast } from "react-toastify";
import { getHomeContent } from "api/PageAPI";
import { updateHomeSection } from "api/HomeAPI";
import { uploadImages } from "api/ImageAPI";

const HomeAboutData = () => {
  const section = "short-about";
  const pageId = 1;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [content, setContent] = useState({
    loading: true,
    data: null,
  });
  const [sectionId, setSectionId] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [order, setOrder] = useState(null);
  const [updateName, setUpdateName] = useState("");
  const [tmpOldImg, setTmpOldImg] = useState([]);
  const [payload, setPayload] = useState({
    body: {
      file1: undefined,
      file2: undefined,
      file3: undefined,
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // File upload
  const dropFile1 = (file) => {
    setPayload((prev) => ({
      ...prev,
      body: {
        ...prev.body,
        file1: file,
      },
    }));
    setTmpOldImg([...tmpOldImg, content.data.img[0]]);
  };
  const dropFile2 = (file) => {
    setPayload((prev) => ({
      ...prev,
      body: {
        ...prev.body,
        file2: file,
      },
    }));
    setTmpOldImg([...tmpOldImg, content.data.img[1]]);
  };
  const dropFile3 = (file) => {
    setPayload((prev) => ({
      ...prev,
      body: {
        ...prev.body,
        file3: file,
      },
    }));
    setTmpOldImg([...tmpOldImg, content.data.img[2]]);
  };

  const removeFile1 = () => {
    setPayload({
      ...payload,
      body: {
        ...payload.body,
        file1: undefined,
      },
    });
    setTmpOldImg((prev) => prev.filter((item, index) => index !== 0));
  };
  const removeFile2 = () => {
    setPayload({
      ...payload,
      body: {
        ...payload.body,
        file2: undefined,
      },
    });
    setTmpOldImg((prev) => prev.filter((item, index) => index !== 1));
  };
  const removeFile3 = () => {
    setPayload({
      ...payload,
      body: {
        ...payload.body,
        file3: undefined,
      },
    });
    setTmpOldImg((prev) => prev.filter((item, index) => index !== 2));
  };
  // End file upload

  const showUpdateModal = (name) => {
    setUpdateName(name);
    if (name !== "counter")
      reset({
        [name + "_en"]: content.data[name + "_en"],
        [name + "_id"]: content.data[name + "_id"],
      });
    else {
      let tempResetField = {};
      content.data.counter.forEach((item) => {
        tempResetField = {
          ...tempResetField,
          [item.name.toLowerCase()]: item.count,
        };
      });
      reset(tempResetField);
    }

    onOpen();
  };

  const onSubmit = async (data) => {
    let promise = null;
    let finalBody = {
      page_id: pageId,
      order: order,
      name: content.name,
      body: {
        ...content.data,
      },
    };

    if (updateName === "images") {
      let existingImageId = content.data.img; // this is pass by reference
      let imageBody = null;

      imageBody = [
        payload.body.file1,
        payload.body.file2,
        payload.body.file3,
      ].filter(Boolean);

      const finalImageBody = {
        images: imageBody,
      };

      if (imageBody) {
        setIsUploading(true);
        await uploadImages(finalImageBody, setProgress)
          .then((res) => {
            setIsUploading(false);
            const images = res.data.data.data;

            if (existingImageId.length > 0) {
              tmpOldImg.filter(Boolean).forEach((item, index) => {
                const oldIndex = content.data.img.findIndex(
                  (id) => id === item
                );
                if (~oldIndex) existingImageId[oldIndex] = images[index];
              });
            } else {
              finalBody = {
                ...finalBody,
                body: {
                  ...finalBody.body,
                  img: images,
                },
              };
            }

            setPayload({
              body: {
                file1: undefined,
                file2: undefined,
                file3: undefined,
              },
            });
          })
          .catch((err) => {
            setIsUploading(false);
            console.error(err);
            toast.error("Gagal mengunggah foto, silahkan coba lagi!");
          });
      }
    } else if (updateName === "counter") {
      const counter = [];
      Object.keys(data).forEach((key) => {
        if (key !== "total")
          counter.push({
            name: key,
            count: parseInt(data[key]),
          });
      });

      data.total = counter.reduce((a, b) => parseInt(a) + parseInt(b.count), 0);

      counter.push({
        name: "total",
        count: data.total,
      });

      finalBody = {
        ...finalBody,
        body: {
          ...finalBody.body,
          counter: counter,
        },
      };
    } else {
      finalBody = {
        ...finalBody,
        body: {
          ...finalBody.body,
          ...data,
        },
      };
    }

    finalBody.body = JSON.stringify(finalBody.body);
    promise = updateHomeSection(sectionId, finalBody);

    toast.promise(promise, {
      pending: "Loading...",
      success: "Berhasil di-update!",
      error: "Gagal di-update!",
    });

    promise
      .then((res) => {
        if (res.status === 200) {
          getContent();
          onClose();
          reset();
        }
      })
      .catch((err) => {
        console.error(err);
      });

    onClose();
  };

  const getContent = () => {
    getHomeContent().then((res) => {
      const index = res.data.data.findIndex(
        (item) => item.name === section.replace(/-/g, "_")
      );
      setSectionId(res.data.data[index].id);
      setOrder(res.data.data[index].order);
      setContent({
        loading: false,
        name: res.data.data[index].name,
        data: res.data.data[index].body,
      });
    });
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
    <>
      <CardContainer
        title="Tentang Kami"
        description="Atur element yang ada pada section tentang kami di sini"
      >
        <div className="flex flex-col gap-3">
          <Widget
            isLoading={content?.loading}
            title="Subtitle"
            subtitle={content.data && content.data.subtitle_id}
            icon={<MdShortText />}
            updateUrl={() => showUpdateModal("subtitle")}
          />
          <Widget
            isLoading={content?.loading}
            title="Title"
            subtitle={content.data && content.data.title_id}
            icon={<MdTextFields />}
            updateUrl={() => showUpdateModal("title")}
          />
          <Widget
            isLoading={content?.loading}
            title="Text/Description"
            subtitle={content.data && content.data.text_id}
            icon={<MdDescription />}
            updateUrl={() => showUpdateModal("text")}
          />
          <Widget
            isLoading={content?.loading}
            title="Images"
            subtitle="Gambar"
            icon={<MdPhotoLibrary />}
            updateUrl={() => showUpdateModal("images")}
          />
          <Widget
            isLoading={content?.loading}
            title="Counter"
            subtitle={
              content.data &&
              content.data?.counter
                ?.slice(0, -1)
                .map((item) => item.name)
                .join(", ")
                .toUpperCase()
            }
            icon={<MdNumbers />}
            updateUrl={() => showUpdateModal("counter")}
          />
        </div>
      </CardContainer>
      <BasicModal
        isOpen={isOpen}
        onClose={onClose}
        header="Update Data"
        scrollBehavior="inside"
      >
        {isUploading ? (
          progress !== 100 ? (
            <>
              <div className="text-center">
                <p className="mb-4 text-lg font-semibold text-navy-700">
                  Mengupload gambar ...
                </p>
                <h3 className="mb-3 text-3xl font-bold text-gray-700">
                  {progress}%
                </h3>
              </div>
              <div className="relative h-4 w-full overflow-hidden rounded-2xl bg-gray-300">
                <div
                  className="absolute top-0 left-0 h-full bg-brand-500 
                  transition-all duration-500 ease-linear"
                  style={{
                    width: `${progress}%`,
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <p className="text-center text-lg font-semibold text-navy-700">
                Menyimpan data ...
              </p>
            </>
          )
        ) : (
          <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
            {updateName === "images" ? (
              <div className="flex flex-col gap-5">
                {content.data?.img.length > 0 && (
                  <div className="flex w-full items-center gap-3">
                    <div className="flex flex-1 flex-col gap-3">
                      <div className="h-40 w-full overflow-hidden rounded-xl border-2 border-gray-200 object-center p-1">
                        <img
                          src={
                            process.env.REACT_APP_API_ENDPOINT +
                            "/images/" +
                            content?.data?.img[0]
                          }
                          className="h-full w-full rounded-xl object-cover"
                          alt="Galeri Short About"
                        />
                      </div>
                      <div className="h-40 w-full overflow-hidden rounded-xl border-2 border-gray-200 object-center p-1">
                        <img
                          src={
                            process.env.REACT_APP_API_ENDPOINT +
                            "/images/" +
                            content?.data?.img[1]
                          }
                          className="h-full w-full rounded-xl object-cover"
                          alt="Galeri Short About"
                        />
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="h-40 w-full overflow-hidden rounded-xl border-2 border-gray-200 object-center p-1">
                        <img
                          src={
                            process.env.REACT_APP_API_ENDPOINT +
                            "/images/" +
                            content?.data?.img[2]
                          }
                          className="h-full w-full rounded-xl object-cover"
                          alt="Galeri Short About"
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex w-full items-center gap-3">
                  <div className="flex flex-1 flex-col gap-3">
                    <DropzoneFile
                      text="Upload file"
                      accept={{
                        "image/jpeg": [".jpg", ".jpeg", ".png"],
                      }}
                      dropFile={dropFile1}
                      removeFile={removeFile1}
                      file={payload.body.file1}
                    />
                    <DropzoneFile
                      text="Upload file"
                      accept={{
                        "image/jpeg": [".jpg", ".jpeg", ".png"],
                      }}
                      dropFile={dropFile2}
                      removeFile={removeFile2}
                      file={payload.body.file2}
                    />
                  </div>
                  <div className="flex-1">
                    <DropzoneFile
                      text="Upload file"
                      accept={{
                        "image/jpeg": [".jpg", ".jpeg", ".png"],
                      }}
                      dropFile={dropFile3}
                      removeFile={removeFile3}
                      file={payload.body.file3}
                    />
                  </div>
                </div>
              </div>
            ) : updateName === "text" || updateName === "description" ? (
              <>
                <TextField
                  extra="mb-3"
                  label={`${
                    updateName.charAt(0).toUpperCase() + updateName.slice(1)
                  } (EN)*`}
                  id={`${updateName}_en`}
                  type="text"
                  name={`${updateName}_en`}
                  errors={errors}
                  register={register}
                  rows={10}
                  defaultValue={""}
                  validationSchema={{
                    required: `${
                      updateName.charAt(0).toUpperCase() + updateName.slice(1)
                    } (EN) harus diisi`,
                  }}
                />
                <TextField
                  extra="mb-3"
                  label={`${
                    updateName.charAt(0).toUpperCase() + updateName.slice(1)
                  } (ID)*`}
                  id={`${updateName}_id`}
                  type="text"
                  name={`${updateName}_id`}
                  errors={errors}
                  register={register}
                  rows={10}
                  defaultValue={""}
                  validationSchema={{
                    required: `${
                      updateName.charAt(0).toUpperCase() + updateName.slice(1)
                    } (ID) harus diisi`,
                  }}
                />
              </>
            ) : updateName === "counter" ? (
              <div className="flex gap-3">
                <InputField
                  extra="mb-3 flex-1"
                  label="PKWTT"
                  id="pkwtt"
                  type="number"
                  name="pkwtt"
                  errors={errors}
                  register={register}
                  defaultValue={""}
                  validationSchema={{
                    required: "PKWTT harus diisi!",
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Hanya boleh diisi dengan angka",
                    },
                  }}
                />
                <InputField
                  extra="mb-3 flex-1"
                  label="PKWT"
                  id="pkwtt"
                  type="number"
                  name="pkwt"
                  errors={errors}
                  register={register}
                  defaultValue={""}
                  validationSchema={{
                    required: "PKWT harus diisi!",
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Hanya boleh diisi dengan angka",
                    },
                  }}
                />
                <InputField
                  extra="mb-3 flex-1"
                  label="Outsourcing"
                  id="outsourcing"
                  type="number"
                  name="outsourcing"
                  errors={errors}
                  register={register}
                  defaultValue={""}
                  validationSchema={{
                    required: "Outsourcing harus diisi!",
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Hanya boleh diisi dengan angka",
                    },
                  }}
                />
              </div>
            ) : (
              <>
                <InputField
                  extra="mb-3"
                  label={`${
                    updateName.charAt(0).toUpperCase() + updateName.slice(1)
                  } (EN)*`}
                  id={`${updateName}_en`}
                  type="text"
                  name={`${updateName}_en`}
                  errors={errors}
                  register={register}
                  defaultValue={""}
                  validationSchema={{
                    required: `${
                      updateName.charAt(0).toUpperCase() + updateName.slice(1)
                    } (EN) harus diisi`,
                  }}
                />
                <InputField
                  extra="mb-3"
                  label={`${
                    updateName.charAt(0).toUpperCase() + updateName.slice(1)
                  } (ID)*`}
                  id={`${updateName}_id`}
                  type="text"
                  name={`${updateName}_id`}
                  errors={errors}
                  register={register}
                  defaultValue={""}
                  validationSchema={{
                    required: `${
                      updateName.charAt(0).toUpperCase() + updateName.slice(1)
                    } (ID) harus diisi`,
                  }}
                />
              </>
            )}
            {/* Button Submit & Close */}
            <div className="float-right mt-8 flex gap-3">
              <button
                className="linear rounded-[20px] bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                type="submit"
              >
                Update
              </button>
              <button
                className="linear rounded-[20px] bg-white px-4 py-2 text-base font-semibold text-gray-600 transition duration-200 dark:bg-transparent dark:text-white dark:opacity-90 dark:active:opacity-90"
                onClick={() => onClose()}
                type="button"
              >
                Batal
              </button>
            </div>
          </form>
        )}
      </BasicModal>
    </>
  );
};

export default HomeAboutData;
