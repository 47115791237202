import React, { useEffect, useState } from "react";
import { KeyboardSensor, useSensor, useSensors } from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import LockedLayout from "../page-builder/locked-layout";
import MyPointerSensor from "../page-builder/components/PointerSensor";
import { homeElTypes } from "./variables/elementTypes";
import { getHomeContent } from "api/PageAPI";

const HomePage = () => {
  const [content, setContent] = useState({
    loading: true,
    data: null,
  });
  const [items, setItems] = useState(homeElTypes);
  const sensors = useSensors(
    useSensor(MyPointerSensor, {
      onActivation: (event) => {
        return;
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const getContent = () => {
    getHomeContent(1).then((res) => {
      setContent({
        loading: false,
        data: res.data.data,
      });
    });
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
    <LockedLayout
      title={"Home Page"}
      description={"Kelola konten yang ada di halaman utama website Anda."}
      items={items}
      sensors={sensors}
      arrangeItems={() => {}}
      content={content}
    />
  );
};

export default HomePage;
