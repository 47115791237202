import { useDisclosure } from "@chakra-ui/hooks";
import {
  createCorporateGovernance,
  deleteCorporateGovernance,
  showAllCorporateGovernance,
  updateCorporateGovernance,
} from "api/CorporateGovernanceAPI";
import { TableActionButton } from "components/buttons/TableActionButton";
import DropzoneFile from "components/dropzone/DropzoneFile";
import InputField from "components/fields/InputField";
import BasicModal from "components/modal/BasicModal";
import TableCard from "components/table/TableCard";
import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Trash2 } from "react-feather";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const columnsData = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "JUDUL (ID)",
    accessor: "title_id",
  },
  {
    Header: "JUDUL (EN)",
    accessor: "title_en",
  },
  {
    Header: "TANGGAL",
    accessor: "date",
  },
  {
    Header: "FILE (PDF)",
    accessor: "pdf",
  },
];

const CorporateGovernanceData = () => {
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState([]);
  const [tempData, setTempData] = useState({});
  const [action, setAction] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [payload, setPayload] = useState({
    pdf: undefined,
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // File upload
  const dropFile = (file) => {
    setPayload((prev) => ({
      ...prev,
      pdf: file,
    }));
  };

  const removeFile = () => {
    setPayload({
      ...payload,
      pdf: undefined,
    });
  };
  // End file upload

  const showAllData = () => {
    setLoading(true);
    showAllCorporateGovernance()
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 404)
          toast.error("Data tidak dapat ditemukan!");
      });
  };

  const showModal = async (action, data) => {
    setAction(action);
    if (action === "updating") {
      setTempData({
        id: data?.row?.original?.id,
        title_id: data?.row?.original?.title_id,
        title_en: data?.row?.original?.title_en,
        date: data?.row?.original?.date,
        pdf: data?.row?.original?.pdf
          ? `${process.env.REACT_APP_BASE_URL}${data?.row?.original?.pdf}`
          : null,
      });
      reset({
        date: data?.row.original?.date,
        title_id: data?.row.original?.title_id,
        title_en: data?.row.original?.title_en,
      });
    } else
      reset({
        date: "",
        title_id: "",
        title_en: "",
      });
    onOpen();
  };

  const closeModal = () => {
    reset();
    setTempData({});
    setPayload({});
  };

  const deleteData = (data) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-ui overflow-hidden
          rounded-lg border border-gray-200 bg-white p-8 dark:border-gray-700 dark:bg-gray-800"
          >
            <h1 className="mb-2 text-xl font-bold text-navy-700">
              Konfirmasi Penghapusan
            </h1>
            <p className="text-gray-700">
              Data yang dihapus tidak dapat dikembalikan. <br />
              Apakah anda yakin ingin menghapus data ini?
            </p>
            <div className="mt-5 flex items-center justify-end gap-3">
              <button
                className={`flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full border bg-red-500 px-4 py-2 text-sm font-medium text-white text-opacity-90 transition duration-200 hover:bg-red-700 active:bg-red-700 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
                onClick={() => {
                  setLoading(true);
                  deleteCorporateGovernance(data.row.original.id)
                    .then((res) => {
                      if (res.status === 200) {
                        showAllData();
                        toast.success("Berhasil dihapus!");
                      }
                    })
                    .catch((err) => {
                      setLoading(false);
                      toast.error(err.response.data.message);
                    });
                  onClose();
                }}
              >
                Ya, Hapus
              </button>
              <button
                className={`flex max-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full border bg-transparent px-4 py-2 text-sm font-medium text-gray-700 transition duration-200 sm-max:w-full sm-max:max-w-none sm-max:flex-1`}
                onClick={onClose}
              >
                Batal
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const onSubmit = async (data) => {
    setLoading(true);
    setIsUploading(true);
    onOpen();

    let promise = null;
    let finalBody = null;

    finalBody = new FormData();
    finalBody.append("date", data.date);
    finalBody.append("title_id", data.title_id);
    finalBody.append("title_en", data.title_en);
    if (payload.pdf) finalBody.append("pdf", payload.pdf);

    action === "adding" && (promise = createCorporateGovernance(finalBody));
    action === "updating" &&
      (promise = updateCorporateGovernance(tempData.id, finalBody));

    toast.promise(promise, {
      pending: "Loading...",
      success: `Berhasil ${action === "adding" ? "ditambahkan" : "di-update"}!`,
      error: `Gagal ${action === "adding" ? "ditambahkan" : "di-update"}!`,
    });

    promise
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          showAllData();
          setPayload({
            ...{},
            body: {
              img: undefined,
            },
          });
          onClose();
          closeModal();
          reset();
        }
      })
      .catch((err) => {
        setLoading(false);
        setIsUploading(false);
        toast.error(err.response.data.message);
      });

    setIsUploading(false);
    onClose();
    closeModal();
  };

  const tableHooks = (columns, updateData, deleteData) => {
    return [
      {
        id: "titles",
        Header: "JUDUL",
        Cell: (row) => {
          return `(ID) ${row.cell.row.values["title_id"]}  - (EN) ${row.cell.row.values["title_en"]}`;
        },
      },
      {
        id: "date",
        Header: "TANGGAL",
        Cell: (row) => {
          return row.cell.row.values["date"];
        },
      },
      {
        id: "pdf",
        Header: "FILE PDF",
        Cell: (row) => {
          console.log(row.cell.row.values["pdf"]);
          return (
            <span className="inline-block w-[200px]">
              <a
                href={`${process.env.REACT_APP_BASE_URL}${row.cell.row.values["pdf"]}`}
                target="_blank"
                rel="noreferrer"
                className="block truncate text-brand-500 underline hover:text-brand-600"
              >
                {row.cell.row.values["pdf"].split("/").pop()}
              </a>
            </span>
          );
        },
      },
      {
        id: "action",
        Header: "ACTION",
        Cell: (row) => (
          <TableActionButton
            row={row}
            updateData={updateData}
            deleteData={deleteData}
          />
        ),
      },
    ];
  };

  useEffect(() => {
    showAllData();
  }, []);

  return (
    <>
      <TableCard
        tableData={data}
        columnsData={columnsData}
        tableTitle={"Corporate Governance"}
        addData={showModal}
        updateData={showModal}
        deleteData={deleteData}
        extendTableHooks={tableHooks}
        hiddenColumns={[
          "id",
          "body.title_id",
          "body.title_en",
          "body.date",
          "body.pdf",
        ]}
        loading={loading}
        isCTALoading={loading}
        disabled={loading}
      />
      <BasicModal
        scrollBehavior="inside"
        size="6xl"
        isOpen={isOpen}
        onClose={() => {
          onClose();
          closeModal();
        }}
        header={
          action === "adding"
            ? "Tambah Corporate Governance"
            : "Edit Corporate Governance"
        }
      >
        {isUploading ? (
          <>
            <p className="text-center text-lg font-semibold text-navy-700">
              Menyimpan data ...
            </p>
          </>
        ) : (
          <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-3">
              <div className="flex flex-1 flex-col gap-5">
                {action === "updating" && tempData.img && (
                  <div className="flex items-center">
                    <img
                      src={tempData.img}
                      alt="certificate"
                      className="h-full w-28 rounded-lg object-cover object-center"
                    />
                    <button
                      type="button"
                      className="ml-3 text-red-500 hover:text-red-600"
                      onClick={() => {
                        setPayload({
                          ...payload,
                          body: {
                            ...payload.body,
                          },
                        });
                        setTempData({
                          ...tempData,
                        });
                      }}
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                )}
                <DropzoneFile
                  text="Upload PDF"
                  accept={{
                    "application/pdf": ["pdf"],
                  }}
                  dropFile={dropFile}
                  removeFile={removeFile}
                  file={payload.pdf}
                />
              </div>
              <InputField
                extra="mb-3"
                label="Tanggal Corporate Governance*"
                id="date"
                type="date"
                name="date"
                errors={errors}
                register={register}
                defaultValue={""}
                validationSchema={{
                  required: `Tanggal Corporate Governance tidak boleh kosong!`,
                }}
              />
              <InputField
                extra="mb-3"
                label="Judul Corporate Governance (ID)*"
                id="title_id"
                type="text"
                name="title_id"
                errors={errors}
                register={register}
                defaultValue={""}
                validationSchema={{
                  required: `Judul Corporate Governance (ID) tidak boleh kosong!`,
                }}
              />
              <InputField
                extra="mb-3"
                label="Judul Corporate Governance (EN)*"
                id="title_en"
                type="text"
                name="title_en"
                errors={errors}
                register={register}
                defaultValue={""}
                validationSchema={{
                  required: `Judul Corporate Governance (EN) tidak boleh kosong!`,
                }}
              />
            </div>

            {/* Button Submit & Close */}
            <div className="float-right mt-8 flex gap-3">
              <button
                className={`linear rounded-[20px] bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90`}
                type="submit"
              >
                {action === "adding" ? "Tambah" : "Update"}
              </button>
              <button
                className="linear rounded-[20px] bg-white px-4 py-2 text-base font-semibold text-gray-600 transition duration-200 dark:bg-transparent dark:text-white dark:opacity-90 dark:active:opacity-90"
                onClick={() => {
                  onClose();
                  closeModal();
                }}
                type="button"
              >
                Batal
              </button>
            </div>
          </form>
        )}
      </BasicModal>
    </>
  );
};

export default CorporateGovernanceData;
