export const regions = [
  {
    id: "SEA",
    name_en: "South East Asia",
    name: "Asia Tenggara",
    type: "region",
    countries: ["BN", "KH", "ID", "LA", "MY", "MM", "PH", "SG", "TH", "VN"],
  },
  {
    id: "SOA",
    name_en: "South Asia",
    name: "Asia Selatan",
    type: "region",
    countries: ["AF", "BD", "BT", "IN", "MV", "NP", "PK", "LK", "IR"],
  },
  {
    id: "AU",
    name_en: "Australia",
    name: "Australia",
    type: "region",
    countries: ["AU", "NZ"],
  },
  {
    id: "EU",
    name_en: "Europe",
    name: "Eropa",
    type: "region",
    countries: [
      "AL",
      "AD",
      "AT",
      "BY",
      "BE",
      "BA",
      "BG",
      "HR",
      "CY",
      "CZ",
      "DK",
      "EE",
      "FI",
      "FR",
      "DE",
      "GR",
      "HU",
      "IS",
      "IE",
      "IT",
      "XK",
      "LV",
      "LI",
      "LT",
      "LU",
      "MK",
      "MT",
      "MD",
      "MC",
      "ME",
      "NL",
      "NO",
      "PL",
      "PT",
      "RO",
      "RU",
      "SM",
      "RS",
      "SK",
      "SI",
      "ES",
      "SE",
      "CH",
      "UA",
      "GB",
      "VA",
    ],
  },
  {
    id: "AF",
    name_en: "Africa",
    name: "Afrika",
    type: "region",
    countries: [
      "DZ",
      "AO",
      "BJ",
      "BW",
      "BF",
      "BI",
      "CM",
      "CV",
      "CF",
      "TD",
      "KM",
      "CG",
      "CD",
      "CI",
      "DJ",
      "EG",
      "GQ",
      "ER",
      "SZ",
      "ET",
      "GA",
      "GM",
      "GH",
      "GN",
      "GW",
      "KE",
      "LS",
      "LR",
      "LY",
      "MG",
      "MW",
      "ML",
      "MR",
      "MU",
      "YT",
      "MA",
      "MZ",
      "NA",
      "NE",
      "NG",
      "RE",
      "RW",
      "ST",
      "SN",
      "SC",
      "SL",
      "SO",
      "ZA",
      "SS",
      "SD",
      "TZ",
      "TG",
      "TN",
      "UG",
      "EH",
      "ZM",
      "ZW",
    ],
  },
  {
    id: "NA",
    name_en: "North America",
    name: "Amerika Utara",
    type: "region",
    countries: [
      "AI",
      "AG",
      "AW",
      "BS",
      "BB",
      "BZ",
      "BM",
      "VG",
      "CA",
      "KY",
      "CR",
      "CU",
      "CW",
      "DM",
      "DO",
      "SV",
      "GL",
      "GD",
      "GP",
      "GT",
      "HT",
      "HN",
      "JM",
      "MQ",
      "MX",
      "MS",
      "AN",
      "NI",
      "PA",
      "PR",
      "BL",
      "KN",
      "LC",
      "MF",
      "PM",
      "VC",
      "TT",
      "TC",
      "VI",
      "US",
    ],
  },
  {
    id: "SA",
    name_en: "South America",
    name: "Amerika Selatan",
    type: "region",
    countries: [
      "AR",
      "BO",
      "BR",
      "CL",
      "CO",
      "EC",
      "FK",
      "GF",
      "GY",
      "PY",
      "PE",
      "SR",
      "UY",
      "VE",
    ],
  },
  {
    id: "ME",
    name_en: "Middle East",
    name: "Timur Tengah",
    type: "region",
    countries: [
      "AE",
      "AM",
      "AZ",
      "BH",
      "CY",
      "GE",
      "IQ",
      "IL",
      "JO",
      "KZ",
      "KW",
      "KG",
      "LB",
      "MO",
      "MV",
      "OM",
      "PS",
      "QA",
      "SA",
      "LK",
      "SY",
      "TW",
      "TJ",
      "TR",
      "TM",
      "AE",
      "UZ",
      "YE",
    ],
  },
  {
    id: "AS",
    name_en: "Asia",
    name: "Asia",
    type: "region",
    countries: ["CN", "HK", "JP", "KP", "KR", "MN", "MO", "TW"],
  },
  {
    id: "OC",
    name_en: "Oceania",
    name: "Ocenia",
    type: "region",
    countries: [
      "AS",
      "CK",
      "FJ",
      "PF",
      "GU",
      "KI",
      "MH",
      "FM",
      "NR",
      "NC",
      "NU",
      "NF",
      "MP",
      "PW",
      "PG",
      "WS",
      "SB",
      "TK",
      "TO",
      "TV",
      "VU",
      "WF",
      "WS",
    ],
  },
  {
    id: "SJ",
    name_en: "Svalbard and Jan Mayen",
    name: "Svalbard dan Jan Mayen",
    type: "region",
    countries: ["SJ"],
  },
  {
    id: "IS",
    name_en: "Small Island",
    name: "Pulau Kecil",
    type: "region",
    countries: ["GS", "HM", "PN", "SH", "FK", "MP", "TC", "UM"],
  },
  {
    id: "LNLN",
    name_en: "Others",
    name: "Lain-lain",
    type: "region",
    countries: ["WW"],
  },
];
