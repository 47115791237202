import React from "react";
import UserContext from ".";

const UserProvider = (props) => {
  const [user, setUser] = React.useState({});

  const getUserData = () => {
    const userData = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : {};
    setUser(userData);
  };

  const setUserData = (userData) => {
    localStorage.setItem("user", JSON.stringify(userData));
    setUser(userData);
  };

  React.useEffect(() => {
    getUserData();
  }, []);

  return (
    <UserContext.Provider
      value={{ ...props, user, setUser, setUserData, getUserData }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserProvider;
